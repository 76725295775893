import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useCallback, useRef } from "react";
import API from "../../../../../modules/api";
import "./table.scss";
import {
  AuthorCell,
  CheckboxCell,
  CheckboxCellHeader,
  Details,
  ExpanderCell,
  RevCell,
  SkeletonCell,
  StatusCell,
  TimestampCell,
  TypeCell,
} from "./cells";

const ReactTable = ({
  revisions,
  setRevisions,
  sourceRevId,
  targetRevId,
  selectTargetRevision,
  makeRevisionLink,
  isParentRevId,
  imagesWithSrc,
  coSubmitterData,
}) => {
  // expand a major revision, fetching subRevisions if necessary
  const expandRow = useCallback(
    (row) => {
      if (!row.getIsExpanded() && !row.original.subRevisions.length) {
        const id = `subRevisions/?id=${row.original._id}&type=${row.original.alias}`;
        API["revisions"].findById(id, (err, data) => {
          if (err) throw err;
          data.forEach((element) => {
            element.parentRev = row.original._id;
            element.parentRevisionNumber = row.original.revision;
            element.parentStatus = row.original.status;
            element.isSubRev = true;
          });

          setRevisions(
            revisions.map((rev) => {
              if (rev._id === row.original._id) {
                rev.subRevisions = data;
              }
              return rev;
            })
          );
        });
      }

      row.toggleExpanded();
    },
    [setRevisions]
  );

  const columns = useMemo(
    () => [
      {
        header: () => (
          <CheckboxCellHeader
            sourceRevId={sourceRevId}
            targetRevId={targetRevId}
          />
        ),
        id: "checkbox",
        cell: ({ row }) => (
          <CheckboxCell
            row={row}
            sourceRevId={sourceRevId}
            targetRevId={targetRevId}
            selectTargetRevision={selectTargetRevision}
          />
        ),
      },
      {
        header: () => null,
        id: "expander",
        cell: ({ row }) => <ExpanderCell row={row} expandRow={expandRow} />,
      },
      {
        header: "AUTHOR",
        cell: ({ row }) => {
          const coId = row.original.co?._id;
          const submitter = coId ? coSubmitterData?.[coId] : null;
          return (
            <AuthorCell
              row={row}
              makeRevisionLink={makeRevisionLink}
              imagesWithSrc={imagesWithSrc}
              submitter={submitter}
            />
          );
        },
      },
      {
        header: "TIMESTAMP",
        cell: ({ row }) => (
          <TimestampCell row={row} makeRevisionLink={makeRevisionLink} />
        ),
      },
      {
        header: "TYPE",
        cell: ({ row }) => <TypeCell row={row} />,
      },
      {
        header: "REV",
        cell: ({ row }) => <RevCell row={row} />,
      },
      {
        header: "STATUS",
        cell: ({ row }) => (
          <StatusCell row={row} makeRevisionLink={makeRevisionLink} />
        ),
      },
      {
        header: "DETAILS",
        cell: ({ row }) => (
          <Details comment={row.original.notes ? row.original.notes : "—"} />
        ),
      },
    ],
    [
      targetRevId,
      expandRow,
      imagesWithSrc,
      makeRevisionLink,
      selectTargetRevision,
      sourceRevId,
      coSubmitterData,
    ]
  );

  const table = useReactTable({
    columns,
    data: revisions,
    getSubRows: (row) => row.subRevisions ?? undefined,
    getRowCanExpand: (row) => !!row.original.subRevisions,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  // autoexpand major revision when its subrevision is the source revision
  useEffect(() => {
    if (isParentRevId && isParentRevId.parentRevision) {
      const row = table
        .getRowModel()
        .rows.find((row) => row.original._id === isParentRevId.parentRevision);
      if (row) expandRow(row);
    }
  }, [expandRow, isParentRevId, table]);

  return (
    <div className="custom-history-table">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows.sort((a, b) => b.original.created - a.original.created)
            .map((row) => {
              return (
                <React.Fragment key={row.id}>
                  <tr>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {row.getIsExpanded() && !row.original.subRevisions.length && (
                    <tr
                      className="custom-loader-holder"
                      key={`${row.original._id}-row`}
                    >
                      <td colSpan={2}></td>
                      {Array(6)
                        .fill(null)
                        .map((e, i) => (
                          <td>
                            <SkeletonCell />
                          </td>
                        ))}
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
      <br />
    </div>
  );
};

export default ReactTable;
