import React from "react";
import "rc-tooltip/assets/bootstrap.css";
import { IconButton, Box, styled } from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import {
  HelpOutlineTwoTone,
  BuildCircleOutlined,
  Warning,
  Error,
  Check,
} from "@mui/icons-material";
import {
  ChildrenStatusError,
  MultipleChangeOrdersError,
  InvalidStatusError,
  ChildrenObseleteError,
  NoPrimarySourceWarning,
} from "features/changeorders";
import { CellParams } from "../cell.types";
import { ValidationErrorType } from "features/changeorders/sdk";
import { sdk } from "features/changeorders/sdk/editor";
import { colorPalette } from "components/themes";

const commonIconProps = { width: 18, ml: 0.6 };

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
    color: "#fff",
    fontSize: 14,
    border: "1px solid #000",
    borderRadius: 0,
    padding: "10px",
    maxWidth: 340,
    whiteSpace: "normal",
    fontWeight: "normal",
    overflowWrap: "break-word", // Ensures text wraps within the tooltip
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000",
  },
}));

interface IconWithTooltipProps {
  overlay: string | React.ReactElement;
  icon: React.ReactElement;
  iconStyle?: React.CSSProperties;
  testId?: string;
}

const IconWithTooltip: React.FC<IconWithTooltipProps> = ({
  overlay,
  icon,
  iconStyle = {},
  testId = "",
}) => (
  <CustomTooltip
    title={overlay}
    placement="right"
    arrow
    data-validation-type={testId}
  >
    <IconButton sx={{ m: 0, p: 0 }}>
      {React.cloneElement(icon, {
        style: { ...icon.props.style, ...iconStyle },
      })}
    </IconButton>
  </CustomTooltip>
);

const ValidationRenderCell = ({ row }: CellParams) => {
  const validation = sdk.state.getChangeset(row);
  const [errs] = validation.errors.useStore();
  const [reviewed] = validation.reviewed.useStore();

  const { error, errorType, isReviewed } = sdk.validation.getRowValidation(
    row,
    errs,
    reviewed
  );

  const missingErrorType = !errorType && errorType !== "";

  if (!isReviewed) {
    return (
      <IconWithTooltip
        overlay="Requires validation."
        icon={
          <HelpOutlineTwoTone
            sx={{ color: colorPalette.taupeGray, ...commonIconProps }}
          />
        }
      />
    );
  }

  if (!error || missingErrorType) {
    return (
      <IconWithTooltip
        overlay="Passes validation."
        icon={<Check sx={{ ...commonIconProps, color: colorPalette.green }} />}
      />
    );
  }

  const errorTypeMap = {
    [ValidationErrorType.RequiresValidation]: (
      <IconWithTooltip
        testId={error?.type ?? ""}
        overlay="Requires validation."
        icon={
          <HelpOutlineTwoTone
            sx={{ color: colorPalette.taupeGray, ...commonIconProps }}
          />
        }
      />
    ),
    [ValidationErrorType.ChildStatusBehind]: (
      <IconWithTooltip
        testId={error?.type ?? ""}
        overlay={<ChildrenStatusError row={row} error={error} />}
        icon={<Error color="error" sx={commonIconProps} />}
      />
    ),
    [ValidationErrorType.MultipleCO]: (
      <IconWithTooltip
        testId={error?.type ?? ""}
        overlay={
          <MultipleChangeOrdersError
            row={row}
            error={error}
            handleButtonClick={() => {
              sdk.validation.validateEditorItems([row]);
            }}
          />
        }
        icon={<Error color="error" sx={commonIconProps} />}
      />
    ),
    [ValidationErrorType.NoPrimarySource]: (
      <IconWithTooltip
        testId={error?.type ?? ""}
        overlay={<NoPrimarySourceWarning row={row} error={error} />}
        icon={<Warning color="warning" sx={commonIconProps} />}
      />
    ),
    [ValidationErrorType.ObsoleteChildren]: (
      <IconWithTooltip
        testId={error?.type ?? ""}
        overlay={<ChildrenObseleteError row={row} error={error} />}
        icon={<Warning color="warning" sx={commonIconProps} />}
      />
    ),
    [ValidationErrorType.Status]: (
      <IconWithTooltip
        testId={error?.type ?? ""}
        overlay={<InvalidStatusError row={row} error={error} />}
        icon={<Error color="error" sx={commonIconProps} />}
      />
    ),
    [ValidationErrorType.Revision]: (
      <IconWithTooltip
        testId={error?.type ?? ""}
        overlay={`Revision Error: ${error?.message ?? ""}`}
        icon={<Error color="error" sx={commonIconProps} />}
      />
    ),
    [ValidationErrorType.IsValid]: (
      <IconWithTooltip
        overlay="Passes validation."
        icon={<Check sx={{ ...commonIconProps, color: colorPalette.green }} />}
      />
    ),
    [ValidationErrorType.ObsoleteParent]: (
      <IconWithTooltip
        overlay={error?.message ?? ""}
        icon={<Warning color="warning" sx={commonIconProps} />}
      />
    ),
  };

  return errorTypeMap[errorType];
};

const ValidationColumnHeader = () => (
  <BuildCircleOutlined
    sx={{ ...commonIconProps, color: colorPalette.taupeGray }}
  />
);

const DURO_VALIDATION_CELL_FIELD = "errorGroup";

export const ValidationCell = {
  renderCell: ValidationRenderCell,
  renderHeader: ValidationColumnHeader,
  field: DURO_VALIDATION_CELL_FIELD,
  width: 20,
};

export const TooltipWrapper = styled(Box)({
  background: colorPalette.black,
  borderRadius: "5px",
  padding: "10px",
  width: 340,
  height: 190,
});
