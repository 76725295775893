import { gql, useQuery } from "@apollo/client";
import { ChangeOrder } from "design/models/changeorder";
import { client } from "graphql/apolloClient";
import {
  CoBasicFragment,
  CoBasicUserFragment,
} from "graphql/fragment/changeOrderFragment";
import { ChangeOrderInfoFragment } from "graphql/fragment/changeOrderInfoFragment";
import { useParams } from "react-router-dom";
import { useComponentTreeQuery } from "./componentsQueries";
import { avatarClasses } from "@mui/material";
import { useMemo } from "react";
import RightSearchMenu from "v1/components/ui/right-search-menu";

export const GET_CHANGE_ORDER_BY_IDS = gql`
  query ChangeOrderByIDQuery($ids: [ID]) {
    changeOrdersByIds(ids: $ids) {
      ...coBasicFragment
    }
  }
  ${CoBasicFragment}
`;

export const CHECK_MULTIPLE_CO_COMPONENTS_QUERY = gql`
  query ComponentsByIds($ids: [ID]) {
    componentsByIds(ids: $ids) {
      id
      changeOrders {
        ...changeOrderInfoFragment
      }
    }
  }
  ${ChangeOrderInfoFragment}
`;

export const CHECK_MULTIPLE_CO_PRODUCTS_QUERY = gql`
  query ProductsByIds($ids: [ID]) {
    productsByIds(ids: $ids) {
      id
      changeOrders {
        ...changeOrderInfoFragment
      }
    }
  }
  ${ChangeOrderInfoFragment}
`;

export const CHANGE_ORDER_APPROVERS = gql`
  query ($id: ID) {
    userById(id: $id) {
      primaryCompany {
        users(status: ENABLED) {
          ...userInfoFragment
        }
      }
    }
  }
  ${CoBasicUserFragment}
`;

export const GET_CHANGE_ORDER_APPROVERS_TEMPLATES = gql`
  query GetChangeOrderApprovalTemplates {
    changeOrderApprovalTemplates {
      id
      templateName
      approvalType
      isPublic
      lastUsed
      approvers {
        ...userInfoFragment
      }
      notifiers {
        ...userInfoFragment
      }
      externalUsers
    }
  }
  ${CoBasicUserFragment}
`;

export const CREATE_CHANGE_ORDER_APPROVERS_TEMPLATE = gql`
  mutation CreateChangeOrderApprovalTemplate(
    $input: CreateChangeOrderApprovalTemplateInput
  ) {
    createChangeOrderApprovalTemplate(input: $input) {
      approvalType
      approvers {
        ...userInfoFragment
      }
      notifiers {
        ...userInfoFragment
      }
      externalUsers
      isPublic
      templateName
    }
  }
  ${CoBasicUserFragment}
`;

export const UPDATE_CHANGE_ORDER_APPROVERS_TEMPLATE_LASTUSED = gql`
  mutation UpdateChangeOrderApprovalTemplate(
    $input: UpdateChangeOrderApprovalTemplateInput!
  ) {
    updateChangeOrderApprovalTemplate(input: $input) {
      id
      lastUsed
    }
  }
`;

export const UPDATE_CHANGE_ORDER_APPROVERS_TEMPLATE = gql`
  mutation UpdateChangeOrderApprovalTemplate(
    $input: UpdateChangeOrderApprovalTemplateInput!
  ) {
    updateChangeOrderApprovalTemplate(input: $input) {
      approvalType
      approvers {
        ...userInfoFragment
      }
      notifiers {
        ...userInfoFragment
      }
      externalUsers
      isPublic
      templateName
    }
  }
  ${CoBasicUserFragment}
`;

export const GET_CHANGE_ORDER_HISTORY_BY_COMPONENT_ID = gql`
  query ChangeOrdersByIds($ids: [ID]) {
    changeOrdersByIds(ids: $ids) {
      id
      resolution
      history {
        action
        user {
          avatar {
            id
          }
          id
          firstName
          lastName
        }
      }
    }
  }
`;

type SubmitterResponse = {
  avatar: string;
  id: string;
  firstName: string;
  lastName: string;
};
type ChangeOrderSubmitterResponse = {
  changeOrdersByIds: {
    id: string;
    history: {
      action: string;
      user: {
        avatar: {
          id: string;
        };
        id: string;
        firstName: string;
        lastName: string;
      };
    }[];
  }[];
};

type SubmitterMap = Record<string, SubmitterResponse>;

export const useCOSubmittersByComponentIds = (ids: string[]) => {
  const { data, loading, error } = useQuery<ChangeOrderSubmitterResponse>(
    GET_CHANGE_ORDER_HISTORY_BY_COMPONENT_ID,
    {
      variables: {
        ids: ids,
      },
      fetchPolicy: "no-cache",
      skip: !ids || !ids.length,
    }
  );

  const submitterMapData: SubmitterMap = useMemo(() => {
    if (!data) return {};
    return data.changeOrdersByIds.reduce<SubmitterMap>((acc, coData) => {
      const submitter = coData?.history?.find(
        (item) => item.action === "SUBMITTED"
      );
      if (submitter) {
        acc[coData.id] = {
          ...submitter.user,
          avatar: submitter.user.avatar?.id,
        };
      }
      return acc;
    }, {});
  }, [data]);

  return { submitterMapData, loading };
};

export const ARCHIVE_CHANGE_ORDER_APPROVER_TEMPLATE = gql`
  mutation ArchiveChangeOrderApprovalTemplate($id: ID!) {
    archiveChangeOrderApprovalTemplate(id: $id)
  }
`;

export async function getChangeOrderByIds(ids: string[] = []) {
  return await client.query<{ changeOrdersByIds: ChangeOrder[] | null }>({
    query: GET_CHANGE_ORDER_BY_IDS,
    variables: { ids },
    fetchPolicy: "no-cache",
  });
}

export const useChangeOrderQuery = () => {
  const params = useParams<{ id: string }>();
  const {
    data,
    error,
    loading: coLoading,
  } = useQuery<{
    changeOrdersByIds: ChangeOrder[];
  }>(GET_CHANGE_ORDER_BY_IDS, {
    variables: {
      ids: [params?.id],
    },
    fetchPolicy: "no-cache",
  });

  const coData = data?.changeOrdersByIds?.[0];
  const treeDataIds = [
    ...(coData?.products.map((p) => p.id) ?? []),
    ...(coData?.components.map((c) => c.id) ?? []),
  ];

  const { treeData, loading: componentsLoading } = useComponentTreeQuery(
    treeDataIds,
    -1
  );
  const loading = coLoading || componentsLoading;

  let changeorder: ChangeOrder | undefined;

  if (coData && treeData) {
    changeorder = {
      ...coData,
      tree: treeData,
      treeData: treeData.reduce(
        (acc, item) => ({
          ...acc,
          [item.treeId]: {
            ...item,
            id: item.cpn.displayValue,
          },
        }),
        {}
      ),
    };
  }

  return {
    error,
    loading,
    changeorder: !loading ? changeorder : undefined,
  };
};
