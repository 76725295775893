import { ValidationTooltipType } from "features/changeorders/components/tooltips/types";

export type FoundChangeOrder = {
  id: string;
  status: string;
  con: {
    displayValue: string;
  };
};

export type FoundChangeOrders = {
  id: string;
  changeOrders: FoundChangeOrder[];
};

export type ComponentsExistsQueryResponse = {
  componentsByIds: FoundChangeOrders[] | null;
};

export type ProductsExistsQueryResponse = {
  productsByIds: FoundChangeOrders[] | null;
};

export type ClientModulesValidationOpts = {
  status: string;
  revSchemeType: string;
  libraryType: string;
  isClient: boolean;
  previousRevision: string;
  revisionBump: boolean;
};

export type ClientModulesSchemaField = {
  value: string;
};

export enum ValidationErrorType {
  MultipleCO = "multiple-co",
  Revision = "revision",
  NoPrimarySource = "no-primary-source",
  Status = "status",
  ChildStatusBehind = "child-status-behind",
  ObsoleteChildren = "obsolete-children",
  RequiresValidation = "requires-validation",
  IsValid = "is-valid",
  ObsoleteParent = "obsolete-parent",
}

export const ValidationErrorTypeReadableMap: Record<
  ValidationErrorType,
  string
> = {
  [ValidationErrorType.MultipleCO]: "Multiple Change Orders",
  [ValidationErrorType.Revision]: "Revision",
  [ValidationErrorType.NoPrimarySource]: "No Primary Source",
  [ValidationErrorType.Status]: "Status",
  [ValidationErrorType.ChildStatusBehind]: "Child Status Behind",
  [ValidationErrorType.ObsoleteChildren]: "Obsolete Children",
  [ValidationErrorType.RequiresValidation]: "Requires Validation",
  [ValidationErrorType.IsValid]: "Component is Valid",
  [ValidationErrorType.ObsoleteParent]: "Obsolete Parent",
};

export enum ValidationErrorGroup {
  RequiresValidation = "RequiresValidation",
  Warning = "Warning",
  Errors = "Errors",
  IsValid = "IsValid",
}

export const ValidationErrorTypeGroups: Record<
  ValidationErrorType,
  ValidationErrorGroup
> = {
  [ValidationErrorType.RequiresValidation]:
    ValidationErrorGroup.RequiresValidation,
  [ValidationErrorType.Revision]: ValidationErrorGroup.Errors,
  [ValidationErrorType.Status]: ValidationErrorGroup.Errors,
  [ValidationErrorType.ChildStatusBehind]: ValidationErrorGroup.Errors,
  [ValidationErrorType.ObsoleteChildren]: ValidationErrorGroup.Warning,
  [ValidationErrorType.ObsoleteParent]: ValidationErrorGroup.Warning,
  [ValidationErrorType.NoPrimarySource]: ValidationErrorGroup.Warning,
  [ValidationErrorType.MultipleCO]: ValidationErrorGroup.Errors,
  [ValidationErrorType.IsValid]: ValidationErrorGroup.IsValid,
};

export type ClientModulesSchemaValidationResult = {
  children: string[];
  duplicateChangeOrders?: FoundChangeOrder[];
  value: string;
  message?: string;
  class?: string;
  valid: boolean;
  type?: ValidationErrorType;
} & ClientModulesSchemaField;

export const defaultClientModulesSchemaValidationResult = {
  valid: true,
  value: "",
  children: [],
  message: "",
  type: ValidationErrorType.IsValid,
};

export type ValidationErrorAndComponent = {
  component?: ValidationTooltipType;
  value: boolean;
  duplicateChangeOrders?: FoundChangeOrder[];
  revisionError?: ClientModulesSchemaValidationResult;
  type: () => ChangeOrderValidationErrorTypes;
  priority: number;
};

export type ValidationErrors = Record<string, ValidationErrorAndComponent>;

export type ValidationWarningsAndErrors = {
  errors: ValidationErrors;
};

export interface ValidationResults extends ValidationWarningsAndErrors {
  setMultipleChangeOrder: () => void;
  setInvalidStatus: () => void;
  setInvalidRevision: () => void;
  setNoPrimarySource: () => void;
  setChildStatusBehindParent: () => void;
  setChildComponentObsolete: () => void;
}

// K/V store: {[componentId]: ValidationResults}
export type ValidationRecord = Record<
  string,
  ClientModulesSchemaValidationResult[]
>;

export type ValidationRecordFactory = {
  data: ValidationRecord;
  add: (id: string) => void;
  remove: (id: string) => void;
  isValid(): boolean;
};

export type ChangeOrderValidationErrorTypes = "error" | "warning" | "disabled";
export type ChangeOrderValidationSettingKey =
  keyof ValidationWarningsAndErrors["errors"];
