import { Box, Link } from "@mui/material";
import { colorPalette } from "@duro/themes";
import { TableActionNavHeader } from "features/changeorders/components";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { sdk } from "features/changeorders";
import { styled } from "@mui/material";
import { Grid, ToolBarItem } from "common/components/grid";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { AutofitIcon } from "assets/icons";
import { ToolbarItemType } from "common/constants";
import { DocumentLink } from "design/models/changeorder";
import DeleteSrc from "v1/assets/icons/cross-icon";
import DropZone from "react-dropzone";
import { MenuItem, Select } from "@mui/material";
import { changeOrderDocumentTypes } from "features/changeorders/constants";
import { addDocuments } from "features/changeorders/components/file-upload/uploadFiles";
import { useCompany } from "design/pages/component/hooks";

export const DocumentsTab = () => {
  const { data: company } = useCompany();
  const maxFileSize = company?.settings?.maxFileSize;
  const maxFileSizeInMB = maxFileSize / 1000000;

  const [documents, setDocuments] = sdk.storage.documentLinks.useStore();
  const [docTypeState, setDocTypeState] = useState<string>("GENERIC");

  const documentsCount = documents.length;

  const handleDeleteDocument = (userId: string) => () => {
    setDocuments((prev) => prev.filter((d) => d.document.id !== userId));
  };

  const documentTypes = changeOrderDocumentTypes.map((e) => e.toString());

  const documentsColumns: GridColDef<DocumentLink>[] = [
    {
      field: "name",
      headerName: "NAME",
      renderCell({ row }) {
        return row.document.name;
      },
      hideable: true,
      sortable: false,
      minWidth: 300,
      flex: 1,
    },
    {
      field: "docType",
      headerName: "DOC TYPE",
      renderCell({ row }) {
        return (
          <Select
            value={row.specs.type.replace(/_/g, " ").toUpperCase()}
            onChange={(e) =>
              setDocuments((prev) =>
                prev.map((d) =>
                  d.document.id === row.document.id
                    ? { ...d, specs: { ...d.specs, type: e.target.value } }
                    : d
                )
              )
            }
          >
            {documentTypes.map((docType: string) => (
              <MenuItem value={docType} key={docType}>
                {docType}
              </MenuItem>
            ))}
          </Select>
        );
      },
      hideable: true,
      sortable: false,
      minWidth: 100,
      maxWidth: 228,
      flex: 1,
    },
    {
      field: "fileType",
      headerName: "FILE TYPE",
      renderCell({ row }) {
        return row.document.name.split(".").pop()?.toUpperCase();
      },
      hideable: true,
      sortable: false,
      minWidth: 200,
      flex: 1,
      align: "left",
    },
    {
      field: "fileSize",
      headerName: "FILE SIZE",
      renderCell({ row }) {
        return row.document.size;
      },
      hideable: true,
      sortable: false,
      minWidth: 100,
      flex: 1,
      align: "right",
    },
    {
      field: "lastUpdated",
      headerName: "LAST UPDATED",
      renderCell({ row }) {
        const lastModifiedTableFormat = new Date(row.specs.lastModified);
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };
        const formattedDate = lastModifiedTableFormat.toLocaleString(
          "en-US",
          options
        );
        return <Box>{formattedDate}</Box>;
      },
      hideable: true,
      sortable: false,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "delete",
      headerName: "",
      renderCell({ row }) {
        return (
          <StyledDeleteIcon onClick={handleDeleteDocument(row.document.id)}>
            <DeleteSrc />
          </StyledDeleteIcon>
        );
      },
      width: 20,
      sortable: false,
    },
  ];

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const wrapperOnDrop = async (files: File[]) => {
    try {
      let docsToDB = await addDocuments(files);
      setDocuments((prev) => [...prev, ...docsToDB]);
    } catch (error) {
      console.error("Error uploading documents: ", error);
    }
  };

  const onDropRejected = (files: File[]) => {
    const file = files[0];
    if (file.size > maxFileSize) {
      alert(`File size exceeds the limit of ${maxFileSizeInMB} MB`);
    }
  };

  const dropZoneLabel = `Drag Files to Upload (max ${maxFileSizeInMB} MB) `;
  return (
    <>
      <TableActionNavHeader
        sx={{
          width: "550px",
          lineHeight: "20px",
          whiteSpace: "pre-wrap",
          marginBottom: "24px",
        }}
      >
        Add supporting documents to this change order. Documents attached to the{" "}
        <br />
        listed{" "}
        <Link
          onClick={() => {
            searchParams.set("tab", "0");
            history.push({ search: searchParams.toString() });
          }}
          sx={{
            color: colorPalette.lightGreen,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Products and Components
        </Link>{" "}
        will automatically be included in this change order.
      </TableActionNavHeader>
      <DropZone
        onDrop={wrapperOnDrop}
        onDropRejected={onDropRejected}
        maxSize={maxFileSize}
        activeClassName="accept-files"
        rejectClassName="reject-files"
        multiple={false}
        style={dropZoneStyles}
      >
        {({ isDragActive }) => (
          <div style={getDropZoneStyles(isDragActive)}>
            <InnerContent style={{ opacity: isDragActive ? 0 : 1 }}>
              <p>
                {dropZoneLabel} <Link>or Browse</Link>
              </p>
            </InnerContent>
          </div>
        )}
      </DropZone>
      <Box sx={{ height: 500, paddingTop: "25px" }}>
        <Grid
          getRowId={(row) => row.document.id}
          apiRef={useGridApiRef()}
          name="Documents"
          columnDefinition={documentsColumns}
          data={documents}
          enableCellFocus={false}
          toolbarItems={toolbarItems}
          autoHeight
          totalCount={
            `${documentsCount} ` +
            (documentsCount === 1 ? `Document` : `Documents`)
          }
          pinnedColumns={{ right: ["delete"] }}
        />
      </Box>
    </>
  );
};

const toolbarItems: ToolBarItem[] = [
  {
    disabled: true,
    Icon: AutofitIcon,
    label: "Autofit",
    type: ToolbarItemType.ACTION,
  },
];

const StyledDeleteIcon = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  cursor: "pointer",
});

const InnerContent = styled(Box)({});

const dropZoneStyles = {
  width: "350px",
  height: "50px",
  border: "1px dashed white",
  display: "flex",
  alignItems: "center",
};

const getDropZoneStyles = (isDragActive: boolean) => ({
  backgroundColor: isDragActive ? colorPalette.green : "transparent",
  margin: isDragActive ? "5px" : "15px",
  height: isDragActive ? "calc(100% - 10px)" : "20px",
  width: isDragActive ? "calc(100% - 10px)" : "300px",
});
