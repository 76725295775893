import { useState, useEffect, FC } from "react";
import InlineIcon from "../../../v1/components/ui/icon/inline-icon";
import AvatarIcon from "../../../v1/assets/icons/user-icon";
import { ApiImageError, ApiImageResponse } from "@duro/api";

type FindByIdFn = (
  id: string,
  callback: (err: ApiImageError | null, response: ApiImageResponse) => void
) => void;

interface AvatarProps {
  findById: FindByIdFn;
  imageId: string;
  imagesWithSrc?: { _id: string; src: string }[];
  index?: number;
  onClick?: () => void;
}

interface ImageWithSrc {
  _id: string;
  src: string;
}

interface LoadedResult {
  avatar: ImageWithSrc | null;
  loaded: boolean;
}

const Avatar: FC<AvatarProps> = ({
  findById,
  imageId,
  imagesWithSrc = [],
  index,
  onClick,
}) => {
  const [avatar, setAvatar] = useState<ImageWithSrc | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const isAlreadyLoaded = (id: string): LoadedResult => {
    let result: LoadedResult = { avatar: null, loaded: false };
    for (let imgWithSrc of imagesWithSrc) {
      if (id === imgWithSrc._id) {
        return { avatar: imgWithSrc, loaded: true };
      }
    }
    return result;
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      findById(
        imageId,
        (err: ApiImageError | null, response: ApiImageResponse | null) => {
          if (err) {
            err.errors.forEach((error) =>
              console.error(`Error loading image: ${error.message}`)
            );
          } else if (
            response &&
            !isAlreadyLoaded(imageId).loaded &&
            imagesWithSrc
          ) {
            imagesWithSrc.push({ _id: response._id, src: response.src });
            setAvatar(response);
          }
        }
      );
    };

    fetchData();
  }, [imageId]);

  return (
    <div
      className="user-avatar-holder"
      onClick={onClick}
      style={{
        width: "32px",
        height: "32px",
        overflow: "hidden",
        borderRadius: "50%",
      }}
    >
      {isLoading ? (
        <span className="face-avatar" key={index + "-face-avatar"}>
          <InlineIcon>
            <AvatarIcon />
          </InlineIcon>
        </span>
      ) : null}
      <span
        className={"user-profile-img " + (isLoading ? "hidden" : "")}
        key={index + "-avatar"}
      >
        <img
          src={avatar?.src ?? ""}
          onLoad={() => setIsLoading(false)}
          alt="avatar"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </span>
    </div>
  );
};

export default Avatar;
