import "./index.scss";
import React, { useState, useMemo } from "react";
import { Box, Tooltip, List, ListItem, Typography, styled } from "@mui/material";
import ArrowRightIcon from "../../../../assets/icons/arrow-right";
import ERPDates from "./erp-dates";
import ErpCheckbox from "./erp-checkbox";
import Utils from "../../../../modules/utils";
import { CustomDuroCheckbox } from "common/components/checkbox";
export const CHECKBOX_FONT_SIZE = "1.3rem";
export const CHECKBOX_LABEL_STYLE = { marginLeft: "0.6rem" };
const ErpOptions = ({ onInputChange, erpOptions }) => {
 
    const [erpToggle, setErpToggle] = useState(false);
    const toggleArrow = () => setErpToggle(!erpToggle);
    const erpOptionsStringified = JSON.stringify(erpOptions)
    
    const details = useMemo(() => {
        const erpOptions = JSON.parse(erpOptionsStringified)
        const isEffectivityChecked = erpOptions.effectivity.isEnabled.checked;
        const isItemTypeChecked = erpOptions.itemType.isEnabled.checked;
        const overrideEffectivity = erpOptions.effectivity.overrideExisting.checked;
        const overrideItemType = erpOptions.itemType.overrideExisting.checked;
        const itemTypeValue = erpOptions.itemType.value.value;
        const effectivityClass = isEffectivityChecked ? "enabled" : "disabled";
        const itemTypeClass = isItemTypeChecked ? "enabled" : "disabled";
        const childComponent = erpOptions.additionalPayloadForNotifications.childComponent.checked;
        const parentAssemblies = erpOptions.additionalPayloadForNotifications.parentAssemblies.checked;

        const isItemTypeEnabled = !!window.__erpItemTypeOptions.length;
        const tooltipMessage = isItemTypeEnabled ? '' : 'Contact sales@durolabs.co'; 


        const itemTypeOptions = Utils.toOptions((Utils.getItemTypeList()).filter(opt => opt.displayName !== "Select"));
        return (
            <ErpWrapper>
                <List className="erp-options">
                    <ListItem className="erp-effectivity">
                        <ErpCheckbox
                            value={isEffectivityChecked}
                            onInputChange={onInputChange}
                            label={{ value: "Set Effectivity" }}
                            name="isEffectivityEnabled"
                        />
                        <span className="custom-datepicker">
                            <ERPDates effectivity={erpOptions.effectivity} onInputChange={onInputChange} />
                        </span>
                        <ErpCheckbox
                            value={overrideEffectivity}
                            onInputChange={onInputChange}
                            itemStateClass={effectivityClass}
                            label={{ value: "Override existing", textClass: effectivityClass }}
                            name="isEffectivityOverrideEnabled"
                        />
                    </ListItem>
                    <Tooltip title={tooltipMessage} placement="right" arrow>
                        <ListItem className="erp-item-type">
                            <ErpCheckbox
                                value={isItemTypeChecked}
                                onInputChange={isItemTypeEnabled ? onInputChange : () => { }}
                                label={{ value: "Bulk Set Item Type" }}
                                name="isItemTypeEnabled"
                                customClass={`item-type ${isItemTypeEnabled ? '' : 'disabled-item-type'}`}
                            />
                            <span className={`select-class custom-datepicker`}>
                                <select
                                    name="selectItemType"
                                    className={itemTypeClass}
                                    value={itemTypeValue}
                                    onChange={onInputChange}
                                >
                                    {itemTypeOptions}
                                </select>
                            </span>
                               {/* <ErpCheckbox
                                value={overrideItemType}
                                onInputChange={onInputChange}
                                itemStateClass={itemTypeClass}
                                label={{ value: "Override existing", textClass: itemTypeClass }}
                                name="isItemTypeOverrideEnabled"
                            /> */}
                        </ListItem>
                    </Tooltip>
                </List>
                <AddLatestReleaseWrapper>
                    <TitleTextField>
                        Add Latest Releases to API webhook payload
                    </TitleTextField>
                    <ChildrenParentTileWrapper>
                        <CheckboxWrapper>
                            <CustomDuroCheckbox
                                checkBoxLabel={"All children components"}
                                checked={childComponent}
                                name="ChildComponentFlag"
                                onChange={onInputChange}
                                checkboxFontSize={CHECKBOX_FONT_SIZE}
                                labelStyles={CHECKBOX_LABEL_STYLE} />
                            <CustomDuroCheckbox
                                checkBoxLabel={"All parent assemblies"}
                                checked={parentAssemblies}
                                onChange={onInputChange}
                                name="ParentAssemblyFlag"
                                checkboxFontSize={CHECKBOX_FONT_SIZE}
                                labelStyles={CHECKBOX_LABEL_STYLE} />
                        </CheckboxWrapper>
                    </ChildrenParentTileWrapper>
                </AddLatestReleaseWrapper>
            </ErpWrapper>
        )}, [erpOptionsStringified, onInputChange])

    return (
        <Box className="erp-wrapper">
            <List className="container">
                <span
                    className={`erp-options-arrow ${erpToggle ? "open" : "close"}`}
                    onClick={toggleArrow}
                >
                    <ArrowRightIcon />
                </span>
                <span className="erp-title">ERP Options</span>
            </List>
            {erpToggle && (
                details
            )}
        </Box>
    );
}

export default ErpOptions;

export const ErpWrapper = styled(Box)({
    display: "flex"
});

export const AddLatestReleaseWrapper = styled(Box)({
    display: "flex", 
    flexDirection: "column",
    paddingTop: "2rem",
    paddingLeft: "2rem",
});

export const TitleTextField = styled(Typography)({
    fontWeight: "bold" 
});

export const ChildrenParentTileWrapper = styled(Typography)({
    display: "flex",
    alignItems: "center", 
    justifyContent: "space-between" 
});

export const CheckboxWrapper = styled(Typography)({
    display: "flex", 
    flexDirection: "column", 
    gap: "0.8rem", 
    marginTop: "1rem", 
});