import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Tooltip from "rc-tooltip";
import Link from "../../../ui/link";
import LazyInput from "../../../ui/lazy-input/input.js";
import InlineIcon from "../../../ui/icon/inline-icon.js";
import LinkIcon from "../../../../assets/icons/open-link-icon.js";
import { ModelType, extractCpnRules } from "utils/cpn";
import { useDispatch } from "react-redux";
import buildAction from "v1/helpers/buildAction";
import styled from "@emotion/styled";
import { createStore } from "common/context/store";
import ComponentActions from "v1/action-types/component";
import ProductActions from "v1/action-types/product";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

const initialFreeFormState = {
  isRemoteFreeForm: false,
  isEditing: false,
  isEnabled: false,
  isModified: false,
  originalValue: "",
  inputValue: "",
}

const freeFormStore = createStore(initialFreeFormState);

function resetFreeFormState() {
  freeFormStore.setState(() => initialFreeFormState);
}

const FreeFormEditActions = (props) => {
  const { cpnIsProductOrComponent, hasCpnEditStateChanged } = props;
  
  const [
    { isEditing, inputValue, originalValue, isRemoteFreeForm, isModified },
    setFreeFormContext,
  ] = freeFormStore.useStore();
  
  const ProductOrComponentDispatchAction = cpnIsProductOrComponent.toLowerCase() === "prd" ? ProductActions : ComponentActions

  const dispatch = useDispatch();
  
  const handleSaveClick = () => {
    if (inputValue === "") return;
    setFreeFormContext((prev) => ({
      ...prev,
      isEditing: false,
      isEnabled: true,
      isModified: prev.originalValue !== prev.inputValue,
    }));

    hasCpnEditStateChanged?.(false);

    dispatch(
      buildAction(ProductOrComponentDispatchAction.UPDATE_EDIT_FORM_INPUT_STATE, {
        cpnType: window.__currentCompanyCpnType,
        name: "cpn",
        value: inputValue,
        overrideValidation: true,
        overrideWithFreeFormCPN: true
      })
    );
  };

  const handleCancelClick = () => {
    setFreeFormContext((prev) => ({
      ...prev,
      isEditing: false,
      isEnabled: false,
      isModified: false,
      inputValue: prev.originalValue,
    }));

    hasCpnEditStateChanged?.(false);

    dispatch(
      buildAction(ProductOrComponentDispatchAction.UPDATE_EDIT_FORM_INPUT_STATE, {
        cpnType: window.__currentCompanyCpnType,
        name: "cpn",
        value: originalValue,
        overrideValidation: true,
      })
    );
  };

  const handleEditIconClick = () => {
    let prefilledInput = "";

    // If the CPN is already a free-form CPN, then prefilledInput should be the original value.
    if (isRemoteFreeForm) {
      prefilledInput = originalValue;
    } else if (isModified) {
      prefilledInput = inputValue;
    }

    hasCpnEditStateChanged?.(true);

    setFreeFormContext((prev) => ({
      ...prev,
      isEditing: true,
      isEnabled: true,
      isModified: false,
      inputValue: prefilledInput,
    }));
  };

  return isEditing ? (
    <>
      <IconButton onClick={handleSaveClick} style={{ padding: "0 5px" }}>
        <CheckIcon style={{ color: "#3cd1b5", width: 16 }} />
      </IconButton>
      <IconButton onClick={handleCancelClick} style={{ padding: "0 8px" }}>
        <CloseIcon style={{ color: "#fff", width: 16 }} />
      </IconButton>
    </>
  ) : (
    <IconButton onClick={handleEditIconClick}>
      <EditIcon
        style={{ color: "#fff", width: 16, left: -26, position: "absolute" }}
      />
    </IconButton>
  );
};

const FreeFormOverrideEditor = ({ children }) => {
  const [{ isEditing, isEnabled, inputValue }, setFreeFormContext] =
    freeFormStore.useStore();

  if (isEditing && isEnabled) {
    return (
      <>
        <CpnLazyInput
          type="text"
          name="cpnFreeForm"
          value={inputValue}
          placeholder="Enter free form CPN"
          onChange={(e) => {
            setFreeFormContext((prev) => ({
              ...prev,
              inputValue: e.target.value,
            }));
          }}
        />
      </>
    );
  }
  
  return <>{children}</>;
};

const CpnEditField = ({
  cpnDuplicateTooltip,
  inputs,
  categoryCPN,
  cpnVariantScheme,
  onInputChange,
  cpnVariant_disabled,
  user,
  vendorLabel,
  freeFormCpnType,
  isAllowedFreeFormOverride,
  cpnIsProductOrComponent,
}) => {

  useEffect(() => {
    return () => {
      resetFreeFormState();
    }
  }, [])

  const [isFreeFormEditing, setIsFreeFormEditing] = useState(false);
  const [freeFormState] = freeFormStore.useStore();
  const { isModified, isEditing, isRemoteFreeForm } = freeFormState;
  
  useEffect(() => {
    freeFormStore.setState((prev) => ({
      ...prev,
      originalValue: inputs.cpn.value,
      inputValue: freeFormCpnType ? inputs.cpn.value : "",
      isEnabled: freeFormCpnType,
      isRemoteFreeForm: freeFormCpnType,
    }));
  }, [freeFormCpnType]);

  // Differentiate vendor fields.
  const getCpnClass =
    vendorLabel && (freeFormCpnType || isAllowedFreeFormOverride)
      ? "editable-vendor-fields"
      : "edit-cpn-field";
  
  const cpnFreeFormClass = (freeFormCpnType || isAllowedFreeFormOverride)
    ? "free-form-input"
    : "";
  const { useTwoVariableScheme = false } = extractCpnRules(
    user?.data?.activeLibrary,
    ModelType.COMPONENT
  );

  if (useTwoVariableScheme) { inputs.useTwoVariableScheme = true }
  
  const isStatusDesign = inputs.status.value === "DESIGN";
  const isProduct = cpnIsProductOrComponent.toLowerCase() === "prd"
  const isSuffixEditable = isStatusDesign && !!user?.data?.activeLibrary?.cpnRules?.isAllowedTwoVariableSuffixEdit

  const enabledVariantField = !isRemoteFreeForm && !isModified && !isFreeFormEditing;  
  const isFreeFormOverrideEditable = isStatusDesign && isAllowedFreeFormOverride && !isProduct
  const isExtraTwoDigitVariant = window.__cpnSchemeType === 'EXTRA-TWO-DIGIT-VARIANT';

  const handleFreeFormEditStateChange = (isEditing) => {
    setIsFreeFormEditing(isEditing);
  }
    
  const wrapperStyles = {
    color: "transparent",
    padding: 0,
  };

  if (freeFormCpnType && !isEditing) {
    wrapperStyles.color = "#888";
    wrapperStyles.padding = "0 10px";
  }

  if (isModified && !isEditing) {
    wrapperStyles.color = "#ed5f63";
    wrapperStyles.padding = "0 10px";
  }

  return (
    <div className="inner-info pov diff-cpn-section">
      <span className="inner-attribute">
        CPN
        <FreeFormIconsContainer>
          {isFreeFormOverrideEditable && (
            <FreeFormEditActions
              cpnIsProductOrComponent={cpnIsProductOrComponent}
              hasCpnEditStateChanged={handleFreeFormEditStateChange}
            />
          )}
        </FreeFormIconsContainer>
      </span>
      <div
        className={`inner-value ${cpnFreeFormClass}`}
        style={{ marginLeft: "auto", width: "100%", display: "inline-flex"}}
      >
        <EditCPNWrapper
          borderColor={wrapperStyles.color}
          padding={wrapperStyles.padding}
        >
          <span
            className="floatLeft"
            style={{
              ...(freeFormCpnType && {
                marginLeft: "auto",
                width: "100%",
              }),
            }}
          >
            <div className={getCpnClass}>
              <FreeFormOverrideEditor
                isAllowedFreeFormOverride={isAllowedFreeFormOverride}
              >
                <CpnContentDisplay
                  duplicateTooltip={cpnDuplicateTooltip}
                  inputs={inputs}
                  categoryCPN={categoryCPN}
                  isSuffixEditable={isSuffixEditable}
                  useTwoVariableScheme={useTwoVariableScheme}
                  onInputChange={onInputChange}
                  cpnIsProductOrComponent={cpnIsProductOrComponent}
                />
              </FreeFormOverrideEditor>
            </div>
          </span>
        </EditCPNWrapper>
        {cpnVariantScheme && user.data?.currentEnv === "LIVE" && enabledVariantField &&
          (!isExtraTwoDigitVariant || (isExtraTwoDigitVariant && isStatusDesign)) && (
          <CpnVariantField
            cpnVariant_disabled={cpnVariant_disabled}
            cpnFreeFormClass={cpnFreeFormClass}
            inputs={inputs}
            onInputChange={onInputChange}
          />
        )}
      </div>
    </div>
  );
};

const CpnContentDisplay = ({
  duplicateTooltip,
  inputs,
  categoryCPN,
  isSuffixEditable,
  useTwoVariableScheme,
  onInputChange,
  cpnIsProductOrComponent,
}) => {
  const [{ isEnabled, isRemoteFreeForm, inputValue, isModified }] =
    freeFormStore.useStore();

  const isSpaceXCpnSuffixEditable = useTwoVariableScheme && isSuffixEditable;

  const cpnIsProduct = cpnIsProductOrComponent.toLowerCase() === "prd";
  const isStatusDesign = inputs.status?.value === "DESIGN";
  const isExtraTwoDigitVariant = window.__cpnSchemeType === 'EXTRA-TWO-DIGIT-VARIANT';
  const variantSuffix = inputs.cpnVariant?.value;

  if (isExtraTwoDigitVariant && variantSuffix && !isStatusDesign) {
    return `${categoryCPN}-${variantSuffix}`;
  }

  if (isModified) {
    return inputValue;
  }

  if (isEnabled || isRemoteFreeForm) {
    return categoryCPN;
  }

  if (isSpaceXCpnSuffixEditable && !cpnIsProduct) {
    const duplicateOf = isEnabled ? duplicateTooltip : inputs.suffixDuplicateOf;

    return (
      <TooltipDisplay duplicateOf={duplicateOf}>
        <EditableCpnFieldDisplay
          inputs={inputs}
          categoryCPN={categoryCPN}
          onInputChange={onInputChange}
        />
      </TooltipDisplay>
    );
  }

  return categoryCPN;
};

const TooltipDisplay = ({ duplicateOf, children }) => {
  return (
    <Tooltip
      placement={"right"}
      visible={duplicateOf}
      overlayClassName={"simple-rc-tip error"}
      getTooltipContainer={() => document.querySelector("#routes")}
      overlay={
        duplicateOf && Object.keys(duplicateOf).length ? (
          <div>
            <p>
              <span className="link-text">{duplicateOf.errorMessage}</span>
              <br />
              <Link
                to={duplicateOf.viewLink}
                target="_blank"
                className="open-link-holder white"
              >
                <span className="link-text">
                  {duplicateOf.linkMessage}
                  <IconWrapper>
                    <LinkIcon />
                  </IconWrapper>
                </span>
              </Link>
            </p>
          </div>
        ) : (
          ""
        )
      }
    >
      {children}
    </Tooltip>
  );
};

const EditableCpnFieldDisplay = ({ inputs, categoryCPN, onInputChange }) => {
  const [suffixCounterValue, setSuffixCounterValue] = useState(inputs.suffixCounter?.value || "");

  useEffect(() => {
    setSuffixCounterValue(inputs.suffixCounter?.value || "");
  }, [categoryCPN]);

  return (
    <EditableCpnField>
      <CpnValue>{categoryCPN.slice(0, -2)}</CpnValue>
      <SuffixCounter>
        <LazyInput
          name="suffixCounter"
          data-tip={inputs.suffixCounter.message}
          data-place="right"
          data-type="error"
          className={inputs.suffixCounter.valid ? "" : "invalid"}
          value={suffixCounterValue}
          onChange={onInputChange}
          style={{ width: "40px" }}
        />
      </SuffixCounter>
    </EditableCpnField>
  );
};

const CpnVariantField = ({
  cpnVariant_disabled,
  cpnFreeFormClass,
  inputs,
  onInputChange,
}) => {
  return (
    <div className={`edit-cpn-variant-field ${cpnFreeFormClass}`}>
      -
      <LazyInput
        type="text"
        name="cpnVariant"
        className={`${inputs.cpnVariant.class || ''} ${!inputs.cpnVariant.valid ? 'invalid' : ''}`}
        value={inputs.cpnVariant.value}
        data-place="right"
        data-type="error"
        data-tip={inputs.cpnVariant.message}
        onChange={onInputChange}
        disabled={cpnVariant_disabled}
      />
    </div>
  );
};

export default CpnEditField;

export const FreeFormIconsContainer = styled(Box)({
  position: "absolute",
  left: 58,
  top: 5,
});

export const EditableCpnField = styled(Box)({
  width: "100%",
});

export const CpnValue = styled(Box)({
  marginRight: "0.1275rem",
  display: "block !important",
  width: "100%",
  textAlign: "right",
});

export const SuffixCounter = styled(Box)({});

export const IconWrapper = styled(InlineIcon)({
  width: "15px",
});

export const EditCPNWrapper = styled(Box)(({ borderColor = "#888" }) => ({
  position: "relative",
  width: "100%",

  boxSizing: "border-box",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: `1px dashed ${borderColor}`,
    pointerEvents: "none",
  },
}));

export const SpaceXWrapper = styled(Box)(({ borderColor }) => ({
  position: "relative",
  width: "130px",
  paddingLeft: "10px",
  boxSizing: "border-box",

  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: `1px dashed ${borderColor}`,
    pointerEvents: "none",
  },
}));

export const IconButton = styled("div")({
  width: "0.6rem",
  height: "0.7rem",
  marginLeft: "0.2rem",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const CpnLazyInput = styled(LazyInput)({
  ":: placeholder": {
    fontSize: "11px",
    fontStyle: "italic",
  },
});
