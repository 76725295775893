import { takeEvery, all, cps, put } from "redux-saga/effects"
import UI                           from "../../../action-types/ui"
import PRODUCT                      from "../../../action-types/product"
import buildAction                  from "../../../helpers/buildAction"
import API                          from "../../../modules/api"
import {privatePaths}               from "../../../app/routes.js"
import Utils                        from "../../../modules/utils"

export function* findRevisionById(action)
{
    try
    {
        const revision = yield cps(API.productRevision.findById, action.payload.id);
        if(!revision)
        {
            let errors = [];
            let err = new Error("Invalid Revision ID");
            errors.push(err);
            yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, donotThrowError: true}));
        }
        else
        {
            try
            {
                let parentId = `${revision.parent}?include=revisions`;
                const product = yield cps(API.products.findById, parentId);

                if (product && product.revisions[product.revisions.length -1]._id === revision._id && !Utils.checkForRestrictedRole(window.__userRole))
                {
                    action.payload.history.replace(`/product/view/${product._id}`)
                }
                else
                {
                    if(!product)
                    {
                        let errors = [];
                        let err = new Error("Invalid Product ID");
                        errors.push(err);
                        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, donotThrowError: true}));
                    }
                    else
                    {
                        let documents_length = revision.documents.length;
                        if(revision.children && revision.children.length == 0){
                            yield put(buildAction(PRODUCT.SET_REVISION_CHILDREN_LOADED, true ));
                        }
                        if(documents_length == 0){
                            yield put(buildAction(PRODUCT.SET_REVISION_DOCUMENTS_LOADED, true ));
                        }

                        let node = document.createElement("span");
                        let cvs  = document.createElement("canvas");
                        let ctx  = cvs.getContext("2d");
                        let txt = "REVISION " + revision.revision;

                        document.body.appendChild(node);
                        node.style.fontSize   = "50px";
                        node.style.fontFamily = "Arial";
                        node.style.fontWeight = "bold";
                        node.innerText        = txt;
                        cvs.width             = node.offsetWidth;
                        cvs.height            = node.offsetHeight + 20;
                        ctx.font              = "bold 40px Arial";
                        ctx.fillStyle         = "#E1E1E1";
                        document.body.removeChild(node);
                        ctx.fillText(txt, 40 , 40);

                        let payload = {
                            revision: revision,
                            product: product,
                            backgroundStyle: {
                                backgroundImage: "url(" + cvs.toDataURL() + ")"
                            }
                        };
                        yield put(buildAction(PRODUCT.SET_REVISION_IN_REVISION_PAGE, payload));
                    }

                }


            }
            catch(err)
            {
                yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
            }
        }

    }
    catch(err)
    {
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err: err,
            closeCb: action.payload.notFoundCb || function(){
                                Utils.redirectToParentRoute(privatePaths.productSearch)
                            }
        }
        yield put(buildAction(UI.SHOW_ALERT, payload))
    }
}

export function* findRevisionNestedDataById(action)
{
    let {id, name} = action.payload;
    try
    {
        const revision = yield cps(API.productRevision.findById, action.payload.id)
        if (!revision)
        {
            action.payload.history.push({ pathname: "/components", state: {query: "type:cmp"}});
        }
        else
        {
            if(name == "documents")
            {
                let {documents} = revision;
                yield put(buildAction(PRODUCT.SET_REVISION_NESTED_DOCUMENTS, documents));
                yield put(buildAction(PRODUCT.SET_REVISION_DOCUMENTS_LOADED, true ));
            }
            else {
                let {children} = revision;
                yield put(buildAction(PRODUCT.SET_REVISION_NESTED_CHILDREN, children));
                yield put(buildAction(PRODUCT.SET_REVISION_CHILDREN_LOADED, true ));
            }
        }
    }
    catch(err)
    {

    }
}

export default function* (getState)
{
    yield all([
        takeEvery(PRODUCT.GET_REVISION_IN_REVISION_PAGE, findRevisionById),
        takeEvery(PRODUCT.GET_REVISION_NESTED_DATA, findRevisionNestedDataById),
    ])
}
