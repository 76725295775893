import {
  takeEvery,
  takeLatest,
  all,
  cps,
  put,
  select
} from "redux-saga/effects"
import UI from "../../../action-types/ui"
import PRODUCT from "../../../action-types/product"
import ASSEMBLY_EDIT from "../../../action-types/assembly-edit"
import ITEM_ASSEMBLY from "../../../action-types/item-assembly"
import SEARCH from "../../../action-types/search"
import { privatePaths } from "../../../app/routes.js"
import { userById } from "graphql/query/userQueries";
import buildAction from "../../../helpers/buildAction"
import API from "../../../modules/api"
import Schemas from "../../../modules/schemas"
import Utils from "../../../modules/utils"
import validations, { validateField } from '../../../modules/validations';
import { getInputs, getProduct }from "./selector"
import { extractCpnRules, formatLegacyCpn } from "utils/cpn"
import checkCoExist from "v1/helpers/CoHelpers"

export function* findProductById(action)
{
    yield put(buildAction(PRODUCT.RESET_PRODUCT_EDIT_FORM_INPUTS))
    yield put(buildAction(ASSEMBLY_EDIT.SET_CHILDREN, {children: [], modified: false}))
    try
    {
        const product = yield cps(API.products.findById, action.payload.id)
        if (typeof product?.cpnId === "object") {
            product.cpnData = product.cpnId;
            product.cpnId = product.cpnId._id;
        }

        if (!product) action.payload.history.push({pathname: "/products", state: {query: "type:prd"}})
        else
        {
            if (action.payload.editMode && checkCoExist(product) && product.status.toLowerCase() !== "design" && product.isEditableWhileInCo !== true)
            {
                throw `This Product is in an unresolved Change Order with Status: ${product.co.status} and can not be edited.`;
            }

            if(action.payload.hasOwnProperty('bulkRevisionValue') && action.payload.hasOwnProperty('bulkStatusValue'))
            {
                if(action.payload.bulkStatusValue !== product.status )
                {
                    product.status = action.payload.bulkStatusValue
                    product.modified = true
                }
                if(action.payload.bulkRevisionValue !== product.revision)
                {
                    product.revision = action.payload.bulkRevisionValue
                    product.modified = true
                }
            }

            yield put(buildAction(PRODUCT.SET_PRODUCT_IN_EDIT_FORM, product))
            yield put(buildAction(ASSEMBLY_EDIT.SET_CHILDREN, {children: product.children || []}))

            if (product && product.archived && !product.revisions.length)
            {
                yield put(buildAction(PRODUCT.EDIT_FORM_EID_CHANGE, {value: product.eid}));
                yield put(buildAction(PRODUCT.EDIT_FORM_NAME_CHANGE, {value: product.name}));
                if(!window.__isIntelligentCpnScheme && window.__currentCompanyCpnType === "FREE-FORM")
                    yield put(buildAction(PRODUCT.EDIT_FORM_CPN_CHANGE, {value: product.cpn}));
            }
        }
    }
    catch(err)
    {
        let { id } = action.payload;
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err,
            closeCb: action.payload.notFoundCb || function(){
                Utils.redirectToParentRoute(privatePaths.productSearch)
            }
        }

        if (err.includes('This Product is in an unresolved Change Order with Status:'))
        {
            let error = {errors: [{message: err}]}

            payload.errors = error.errors;
            payload.err = error;
            payload.errorType = "custom";
            payload.closeCb = () => { Utils.redirectToParentRoute(`/product/view/${id}`) };
            payload.confirmBtnText = "OK";
            payload.errorHeading = "Error";
        }

        yield put(buildAction(UI.SHOW_ALERT, payload))
    }
}

export function* deleteProduct(action)
{
    try
    {
        let deleteRequestBody = {}
        if (action.payload.forceDelete) deleteRequestBody = {forceDelete: true}
        yield cps(API.products.deleteById, action.payload.id, deleteRequestBody)
        action.payload.history.push({pathname: "/products", state: {query: "type:prd"}})
        yield put (buildAction(ITEM_ASSEMBLY.RESET_STATE))
        if (action.payload.viewRoute)
            action.payload.history.push({pathname: "/products", state: {query: "type:prd"}})
        else
        {
            yield put(buildAction(PRODUCT.RESET_PRODUCT_EDIT_FORM, {}))
            if (action.payload.originalProductId)
                action.payload.history.push("/product/view/" + action.payload.originalProductId)
            else
                action.payload.history.push("/product/new")
        }
    }
    catch(err)
    {

        let cb = function()
        {
            action.payload.history.push("/product/edit/" + action.payload.id)
        }
        let payload =
        {
            err: err,
            errorType: "custom",
            type: "errors",
            errors: err.errors,
            closeCb: cb
        }
        yield put(buildAction(UI.SHOW_ALERT, payload))
    }
}

function* onNameChange(action)
{
    let inputs = yield select(getInputs)
    let product = yield select(getProduct)
    let input     = inputs.name
    let value     = action.payload.value

    try
    {
        validateField(inputs.name, validations.product.name, value)
        inputs["nameDuplicateOf"] = null

        if (input.valid)
        {
            input.valid = false
            yield put(buildAction(PRODUCT.UPDATE_EDIT_PRODUCT_FORM_FIELDS, inputs))
            let data = yield cps(API.products.nameExists, {name: value, id: product._id})
            let exists = data.exist
            input.message = exists ? "Name already exists in library." : ""
            input.valid   = !input.message
            input.class   = input.valid ? "" : "invalid"
            inputs["nameDuplicateOf"] = data.duplicate_of
        }
        yield put(buildAction(PRODUCT.UPDATE_EDIT_PRODUCT_FORM_FIELDS, inputs))

    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
}

function* onEidChange(action)
{
    let inputs = yield select(getInputs)
    let product = yield select(getProduct)
    let input     = inputs.eid
    let value     = action.payload.value
    try
    {
        validateField(inputs.eid, validations.product.eid, value)
        inputs["eidDuplicateOf"] = null

        if (input.valid)
        {
            input.valid = false
            yield put(buildAction(PRODUCT.UPDATE_EDIT_PRODUCT_FORM_FIELDS, inputs))
            try
            {
                let payload = {eid: String(value), id: product._id}
                let data = yield cps(API.products.eidExists, payload)
                let exists = data.exist
                input.message = exists ? "EID already exists in library." : ""
                input.valid   = !input.message
                input.class   = input.valid ? "" : "invalid"
                inputs["eidDuplicateOf"] = data.duplicate_of
            }
            catch(err)
            {
                input.message = "Some error has been occured"
                input.valid   = !input.message
                input.class   = input.valid ? "" : "invalid"
                yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
            }
        }
        yield put(buildAction(PRODUCT.UPDATE_EDIT_PRODUCT_FORM_FIELDS, inputs))

    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
}

function* onCpnChange(action)
{
    let inputs    = yield select(getInputs)
    let product   = yield select(getProduct)
    let input     = inputs.cpn
    let value     = action.payload.value
    try
    {
        inputs["cpnDuplicateOf"] = null
        if (input.valid)
        {
            input.valid = false
            yield put(buildAction(PRODUCT.UPDATE_EDIT_PRODUCT_FORM_FIELDS, inputs))
            try
            {
                let payload = {cpn: String(value), id: product._id, company: product.company, lib: product.library}
                let data = yield cps(API.products.cpnExists, payload)
                let exists = data.exist
                input.message = exists ? "CPN already exists in library." : ""
                input.valid   = !input.message
                input.class   = input.valid ? "" : "invalid"
                inputs["cpnDuplicateOf"] = data.duplicate_of
            }
            catch(err)
            {
                input.message = "Some error has been occured"
                input.valid   = !input.message
                input.class   = input.valid ? "" : "invalid"
                yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
            }
        }
        yield put(buildAction(PRODUCT.UPDATE_EDIT_PRODUCT_FORM_FIELDS, inputs))

    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
}

export function* updateInputState(action)
{
    let inputs = yield select(getInputs)
    let product = yield select(getProduct)

    let name   = action.payload.name
    let value  = action.payload.value
    let index  = action.payload.index
    let input  = ""

    let statusesWithIntegerRevision = ["DESIGN", "PROTOTYPE"]
    let statusesWithNonIntegerRevision = ["PRODUCTION", "OBSOLETE"]

    switch(name)
    {
        case "name" :
        {
            inputs.inputChanged = true
            yield put(buildAction(PRODUCT.EDIT_FORM_NAME_CHANGE, {value}))
            break
        }

        case "revision" :
        {
            inputs.inputChanged = true
            validateField(inputs.revision, validations.product.revision, value.toUpperCase(), {status: inputs.status.value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true, defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions})

            if (statusesWithIntegerRevision.includes(product.status) && statusesWithIntegerRevision.includes(inputs.status.value) && inputs.revision.valid)
            {
                // product.revision = value.toUpperCase()
            }

            else if (statusesWithNonIntegerRevision.includes(product.status) && statusesWithNonIntegerRevision.includes(inputs.status.value) && inputs.revision.valid)
            {
                // product.revision = value.toUpperCase()
            }

            if (inputs.status.value === 'DESIGN' && product.status === 'DESIGN')
            {
                if (inputs.revision.valid) product.revision = value.toUpperCase()
            }
            break
        }

        case "status" :
        {
            inputs.inputChanged = true
            inputs.status.value   = value
            if  (
                    statusesWithIntegerRevision.includes(value) &&
                    statusesWithIntegerRevision.includes(product.status) ||
                    statusesWithNonIntegerRevision.includes(value) &&
                    statusesWithNonIntegerRevision.includes(product.status)
                )
            {
                inputs.revision.value = product.revision
            }
            else
            {
                inputs.revision.value = validations.product.revision.normalize({status: inputs.status.value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType}, inputs.revision.value).revision
            }

            validateField(inputs.revision, validations.product.revision, inputs.revision.value, {status: inputs.status.value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true})
            break
        }

        case "eid" :
        {
            inputs.inputChanged = true
            yield put(buildAction(PRODUCT.EDIT_FORM_EID_CHANGE, {value}))
            break
        }

        case "description" :
        {
            inputs.inputChanged = true
            validateField(inputs.description, validations.product.description, value)
            break
        }

        case "mass" :
        {
            inputs.inputChanged = true
            validateField(inputs.mass, validations.product.mass, value, {massPrecisionValue: window.__massPrecisionValue});
            break
        }

        case "team" :
        {
            inputs.inputChanged = true
            input         = inputs.team[index]
            input.value   = Schemas.product.team.normalize(value)
            input.message = Schemas.product.team.validate(input.value)
            input.valid   = !input.message
            input.class   = input.valid ? "" : "invalid"
            break
        }

        case "forecastVolume" :
        {
            inputs.inputChanged = true
            input = inputs.forecasts[index]
            value = value && Number(value) ? parseInt(value) : value
            validateField(input.volume, validations.product.forecasts.volume, value)
            break
        }

        case "forecastsTarget" :
        {
            inputs.inputChanged = true
            input = inputs.forecasts[index]
            let previousInput = inputs.forecasts[index - 1]
            validateField(input.targetDate, validations.product.forecasts.targetDate, value)
            if(inputs.forecasts.length)
            {
                validateField(input.targetDate, validations.product.forecasts.targetDate, value ? value : 0)
                Utils.validateForecastDates(inputs, input);
            }
            break
        }

        case "images" :
        {
            inputs.inputChanged = true
            input       = inputs.images
            input.value = value
            break
        }

        case "documents" :
        {
            inputs.inputChanged = true
            input       = inputs.documents
            input.value = value.documents
            input.valid = value.isUploadedDocuments
            break
        }

        case "children" :
        {
            inputs.inputChanged = true
            input       = inputs.children
            input.value = value.data
            input.valid = value.valid
            break
        }

        case "manufacturers" :
        {
            if (value.data.length !== 0)
                inputs.inputChanged = value.inputChanged
            input       = inputs.manufacturers
            input.value = value.data
            input.valid = value.valid
            break
        }

        case "cpnVariant" :
        {
            inputs.inputChanged = true
            input       = inputs.cpnVariant
            let mode = window.__libraryType === 'GENERAL' ? 'LIVE' : 'SANDBOX';
            const { activeLibrary } = userById();
            const cpnRules = extractCpnRules(activeLibrary, "PRODUCT");
            validateField(input, validations.product.cpnVariant, value, {cpnRules, cpnType: action.payload.cpnType, isIntelligentCpnScheme: window.__isIntelligentCpnScheme, nonIntelligent: window.__nonIntelligent, mode: mode});

            if (input.valid)
            {
                input.valid = false
                yield put(buildAction(PRODUCT.UPDATE_EDIT_PRODUCT_FORM_FIELDS, inputs))
                try
                {
                    let cpn = null;
                    // ToDo: Need to handle the cpn already exists logic on the plm-api side
                    if (window.__cpn_rules() ) {
                      cpn = formatLegacyCpn({ 
                        cpn: product.cpn, hasVariant: product.variantGroup, library: activeLibrary, modelType: "PRODUCT", variant: value
                      })
                    }
                    else if(window.__companyIs11DigitCpnType())
                    {
                        cpn = `${product.cpn.split("-")[0]}-${product.cpn.split("-")[1]}-${product.cpn.split("-")[2]}-${String(value)}`;
                    }
                    else if(window.__companyIs9DigitCpnType())
                    {
                        cpn = `${product.cpn.split("-")[0]}-${product.cpn.split("-")[1]}-${String(value)}`;
                    }
                    else if(window.__with6DigitPrefixAndCounter() || window.__nonIntelligentCPN())
                    {
                        cpn = `${product.cpn.split("-")[0]}-${String(value)}`;
                    }
                    else if(window.__conditional01Variant()) {
                        cpn = `${product.cpn.slice(0,10)}${String(value)}`;
                    }
                    else
                    {
                        cpn = product.cpn.slice(0,10) + String(value);
                    }
                    let res = yield cps(API.products.findByCpn, cpn);
                    input.message = (res && res._id !== product._id) ? "CPN already exists in library." : ""
                    input.valid   = !input.message
                    input.class   = input.valid ? "" : "invalid"
                }
                catch(err)
                {
                    input.message = "Some error has been occured"
                    input.valid   = !input.message
                    input.class   = input.valid ? "" : "invalid"
                    yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
                }
            }
            break
        }

        case "cpn" :
        {            
            value = value.trim();
            inputs.cpn.value = value;
            inputs.inputChanged = true;
            if(!action.payload.overrideValidation) {
                const vPayload = {
                    cpnType: action.payload.cpnType,
                    isIntelligentCpnScheme: window.__isIntelligentCpnScheme,
                    nonIntelligent: window.__nonIntelligent
                }
                validateField(inputs.cpn, validations.cpn.value, value, vPayload)
            }
            yield put(buildAction(PRODUCT.EDIT_FORM_CPN_CHANGE, {
                value,
                overrideWithFreeFormCPN: action.payload?.overrideWithFreeFormCPN
            }));
            break
        }
        case "customSpecs": {
            inputs.inputChanged = true;
            const { index } = action.payload;
            const prodCat = window.__categories.find(cat => cat.name === "productSpecs");
            input = Utils.validateCustomSpecs(prodCat, inputs.customSpecs[index], value);
            inputs.customSpecs[index] = input;
            break
        }
        case "itemType" :
        {
            inputs.inputChanged = true
            input       = inputs.itemType
            input.value = value
            break
        }
        case "startDate":
        {
            inputs.inputChanged = true;
            inputs.effectivity.startDate.value = value;
            break;
        }
        case "endDate":
        {
            inputs.inputChanged = true;        
             inputs.effectivity.endDate.value = value;
            break;
        }
            
        default :
        {
            // noop
        }
    }
    if(input.message)
    {
        yield put(buildAction(PRODUCT.SET_EDIT_PAGE_MODIFIED, false ))
    }
    else if (inputs.isUploadedThumbnails)
    {
        yield put(buildAction(PRODUCT.SET_EDIT_PAGE_MODIFIED, true ))
    }
    yield put(buildAction(PRODUCT.UPDATE_EDIT_PRODUCT_FORM_FIELDS, inputs))
}
export function* searchComponets(action)
{
    try
    {
        const data = yield cps(API.search, action.payload.query)
        yield put(buildAction(PRODUCT.SET_EDIT_PAGE_COMPONENT_SEARCH_RESULTS, data.results ))
    }
    catch(err)
    {
        console.error(err)
        yield put(buildAction(PRODUCT.SET_EDIT_PAGE_COMPONENT_SEARCH_RESULTS, [] ))
    }
}
export function* submitProductForm(action)
{
    yield put(buildAction(UI.LOAD_START))
    let product = yield select(getProduct)
    let productViewLink = `/product/view/${product._id}${ window.__userRole === "SUPPLIER" ? '?viewRecent=true' : ''}`
    let productEditLink = `/product/edit/${product._id}`;

    yield put(buildAction(PRODUCT.SET_EDIT_PAGE_MODIFIED, false ))
    try
    {

        const showAll = action.payload.showAll ? "?showAll=true" : "";
        const latestProduct = yield cps(API.products.findById, (product._id + showAll));
        if (checkCoExist(latestProduct) && latestProduct.status.toLowerCase() !== "design" && latestProduct.isEditableWhileInCo !== true)
        {
            let errorsPayload =
            {
                type: "errors",
                errorType: "custom",
                modalClass: 'changeorder-warning',
                confirmBtnText : "Ok",
                donotThrowError: true,
                errorHeading: 'Error',
                errors: [{message: "This product is in an open change order and cannot be edited."}]
            }
            yield put(buildAction(UI.SHOW_ALERT, errorsPayload))
            return
        }

        yield cps(API.products.update, product._id, action.payload.data)
        yield put(buildAction(PRODUCT.RESET_PRODUCT_EDIT_FORM, {}))
        if (window.__userRole === "VENDOR"){
            return action.payload.history.push("/dashboard");
        }
        else
        {
            action.payload.history.push(productViewLink)
            yield put(buildAction(ITEM_ASSEMBLY.GET_ITEM_ASSEMBLY_LIST, {id: product._id}))

            product = yield cps(API.products.findById, product._id)
            let selectedTextForProductList = (product.cpn) + " " + product.name
            let payload = {selectedTextForProductList, _id: product._id, class: "close", buttonHighlight: true}
            yield put(buildAction(ITEM_ASSEMBLY.SET_SELECTED_TEXT_FOR_PRODUCT_LIST, payload))
            yield put(buildAction(SEARCH.CHANGE_SEARCH_TYPE, { state: {query: "type:prd"} }));
        }
    }
    catch(err)
    {
        let payload =
        {
            closeCb: () => { },
            type: "errors",
            errors: err.errors,
            err: err
        }
        if (err.errors[0].message === "Client schema validation error")
        {
            payload.closeCb = () => {},
            payload.errorType = "custom"
            payload.donotThrowError = true
            payload.errors = [{message: "By switching to revision control Duro validation rules are now active. Any errors found must be corrected before you can continue. If you do not wish to correct these errors now, switch back to design mode before saving."}]
        }
        else if(err.errors[0].message.includes("This Product has been modified by another user"))
        {
            let errors = err.errors[0].message.split('Please');
            payload.type = "confirm";
            payload.errorType = "warning";
            payload.heading = "Warning";
            payload.confirmButtonText = "OK";
            payload.text = `<span>${errors[0]}<br/>Please ${errors[1]}</span>`;
            payload.confirmCb = () => {
                window.onbeforeunload = undefined;
                Utils.redirectToParentRoute(productEditLink);
            };
        }
        yield put(buildAction(UI.SHOW_ALERT, payload))
    }
    yield put(buildAction(UI.LOAD_END))
}

export function* uploadImage(action)
{
    let inputs = yield select(getInputs)
    if (action.payload.thumbnailUploaderrors.length > 0)
    {
        let errors = []
        let errorsArray = action.payload.thumbnailUploaderrors
        errors.push({ message: "Error in uploading following files:" })
        errorsArray.forEach(function(error, index)
        {
            errors.push({ message: "("+ (index+1) + ") " + error.file })
        })
        errors.push(errorsArray[0].errors[0])
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, err: new Error(errors[0])}))
    }
    yield put(buildAction(PRODUCT.UPDATE_IMAGES_STATE_ON_EDIT_PAGE, action.payload))

    if (inputs.isUploadedThumbnails)
    {
        yield put(buildAction(PRODUCT.SET_EDIT_PAGE_MODIFIED, true ))
    }
    else
    {
        yield put(buildAction(PRODUCT.SET_EDIT_PAGE_MODIFIED, false ))
    }
}



export default function* (getState)
{
    yield all([
        takeEvery(PRODUCT.GET_PRODUCT_AND_SET_IN_EDIT_PAGE, findProductById),
        takeLatest(PRODUCT.UPDATE_EDIT_FORM_INPUT_STATE, updateInputState),
        takeLatest(PRODUCT.SEARCH_COMPONENTS_IN_EDIT_PAGE, searchComponets),
        takeLatest(PRODUCT.SUBMIT_PRODUCT_EDIT_FORM, submitProductForm),
        takeLatest(PRODUCT.DELETE_PRODUCT, deleteProduct),
        takeEvery(PRODUCT.UPLOAD_IMAGES_ON_EDIT_PAGE, uploadImage),
        takeLatest(PRODUCT.EDIT_FORM_NAME_CHANGE, onNameChange),
        takeLatest(PRODUCT.EDIT_FORM_EID_CHANGE, onEidChange),
        takeLatest(PRODUCT.EDIT_FORM_CPN_CHANGE, onCpnChange),

    ])
}
