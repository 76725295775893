import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Grid, styled, Box, Typography } from "@mui/material";
import { EcoIcon, McoIcon, DcoIcon } from "assets/icons";
import {
  CustomTextArea,
  FieldTitle,
  InputField,
} from "common/components/inputs";
import { colorPalette } from "@duro/themes";
import mixpanel from "common/mixpanel";

import { ERPEffectivity, ERPItemType, sdk } from "features/changeorders";
import { DCOTypeModal } from "./dco-modal.component";
import { ChangeOrderType } from "design/models/changeorder";

import { Helmet } from "react-helmet";
import { makeTitle } from "utils/makeTitle";
import { Expander } from "common/components/expander";
import { getWindow } from "utils/window";
import { DuroCheckbox } from "common/components/checkbox";

export const isEditMode = () =>
  window.location.pathname.includes("/changeorder/edit/");

export const ChangeOrderForm: React.FC = () => {
  const { form } = sdk.storage;
  const [name, setName] = form.name.useStore();
  const [description, setDescription] = form.description.useStore();
  const [type, setType] = form.type.useStore();
  const [expanded, setExpanded] = useState(false);
  const { displayValue, id } = form.con.getState();
  const [open, setOpen] = useState(false);
  const [isNameFieldError] = sdk.storage.isNameFieldError.useStore();
  const [erpWebhooks, setErpWebhooks] =
    form.erpOptions.additionalPayloadForNotifications.useStore();

  const Icons = useMemo(
    () => [
      {
        defaultSelect: type === ChangeOrderType.ECO,
        Icon: EcoIcon,
        label: ChangeOrderType.ECO,
      },
      {
        defaultSelect: type === ChangeOrderType.MCO,
        Icon: McoIcon,
        label: ChangeOrderType.MCO,
      },
      ...(getWindow().__libraryType === "GENERAL" && getWindow().__isDcoEnabled
        ? [
            {
              defaultSelect: type === ChangeOrderType.DCO,
              Icon: DcoIcon,
              label: ChangeOrderType.DCO,
            },
          ]
        : []),
    ],
    [type]
  );

  const onChangeName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [name]
  );

  const onChangeDescription = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(event.target.value);
    },
    [description]
  );

  const isEditView = isEditMode();
  const categoryText = isEditView
    ? "Category Type:"
    : "Start by choosing a category type:*";

  return (
    <EditContainer>
      <Helmet>
        <title>
          {makeTitle(
            displayValue.replace(/^[^-]+-/, type.toString().toUpperCase() + "-")
          )}
        </title>
      </Helmet>

      <EditPane
        sx={{
          paddingBottom: "5px",
        }}
      >
        <Box>
          <CategoryText>{categoryText}</CategoryText>
          <IconGroup>
            {Icons.map((icon) => (
              <CategoryContainer
                key={icon.label}
                onClick={() => {
                  if (icon.label === ChangeOrderType.DCO) {
                    setOpen(true);
                  } else {
                    setType(icon.label as ChangeOrderType);
                  }
                  mixpanel.track("ChangeOrder", {
                    operation: "Set Change Order Type",
                    "Change Order": id,
                    "Change Order Type": icon.label,
                    "Change Order UI Version": "v2",
                  });
                }}
                sx={{
                  ...(icon.label.toLowerCase() ===
                    type.toString().toLowerCase() && {
                    background: colorPalette.dark,
                    color: colorPalette.white,
                    fontWeight: "bold",
                    borderBottom: `2px solid ${colorPalette.green}`,
                    "&:hover": {
                      color: colorPalette.lightGreen,
                      fontWeight: "bold",
                      borderBottom: `2px solid ${colorPalette.green}`,
                    },
                  }),
                }}
              >
                {icon.Icon()} {icon.label}
              </CategoryContainer>
            ))}
          </IconGroup>
        </Box>
      </EditPane>
      <DCOTypeModal open={open} setClose={() => setOpen(false)} />
      <EditPane>
        <InputField
          componentStyles={{ marginTop: "1.25rem" }}
          inputStyles={{
            style: {
              backgroundColor: colorPalette.gray,
            },
          }}
          isRequired={true}
          label="Name"
          labelStyling={{
            color: colorPalette.white,
          }}
          onChange={onChangeName}
          name={name ?? ""}
          value={name ?? ""}
          error={isNameFieldError && isEditMode()}
          placeholder="Enter name"
        />
        <FieldTitle
          label="Description"
          sx={{
            color: colorPalette.white,
            fontSize: "0.75rem",
            marginBottom: "0.5rem",
            marginTop: "1.25rem",
          }}
        />
        <CustomTextArea
          value={description}
          sx={{ fontSize: "0.875rem", height: "5rem !important", mb: 3 }}
          onChange={onChangeDescription}
        />

        <Expander
          title="ERP Options"
          expanded={expanded}
          setExpanded={() => setExpanded(!expanded)}
        >
          <ERPOptionsContainer>
            <Box
              sx={{
                "> div": {
                  display: "grid",
                  gridTemplateColumns: "10fr 75fr 15fr",
                  gap: "10px",
                },
                div: {
                  width: "100%",
                },
                "*": {
                  justifyContent: "left",
                },
              }}
            >
              <ERPEffectivity />
              <ERPItemType />
            </Box>

            <ERPOptionCheckboxContainer>
              <LatestReleases>
                Add Latest Releases to API webhook payload
              </LatestReleases>
              <ERPOptionCheckboxRowContainer>
                <DuroCheckbox
                  onChange={() =>
                    setErpWebhooks((prev) => ({
                      ...prev,
                      childComponent: !erpWebhooks.childComponent,
                    }))
                  }
                  checked={erpWebhooks.childComponent}
                />
                <ERPOptionCheckboxLabel>
                  All children components
                </ERPOptionCheckboxLabel>
              </ERPOptionCheckboxRowContainer>

              <ERPOptionCheckboxRowContainer>
                <DuroCheckbox
                  onChange={() =>
                    setErpWebhooks((prev) => ({
                      ...prev,
                      parentAssemblies: !erpWebhooks.parentAssemblies,
                    }))
                  }
                  checked={erpWebhooks.parentAssemblies}
                />
                <ERPOptionCheckboxLabel>
                  All parent assemblies
                </ERPOptionCheckboxLabel>
              </ERPOptionCheckboxRowContainer>
            </ERPOptionCheckboxContainer>
          </ERPOptionsContainer>
        </Expander>
      </EditPane>
    </EditContainer>
  );
};

const EditContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "100%",
  maxWidth: "1040px",
});

const EditPane = styled(Box)({
  sx: { boxShadow: 4 },
  padding: "15px 15px 25px 44px",
});

const CategoryContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px 20px",
  cursor: "pointer",
  gap: 2,
  "&:hover": {
    color: colorPalette.lightGreen,
  },
});

const IconGroup = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: 200,
  marginTop: 16,
});

const CategoryText = styled(Typography)({
  marginBottom: 5,
  color: colorPalette.taupeGray,
  fontSize: 16,
});

const LatestReleases = styled(Typography)({
  color: colorPalette.white,
  fontSize: "0.8rem",
  fontWeight: "bold",
});

const ERPOptionsContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "70fr 30fr",
  width: "100%",
  alignItems: "center",
  justifyItems: "start",
});

const ERPOptionCheckboxLabel = styled(Typography)({
  fontSize: "0.8rem",
  marginLeft: "0.4rem",
});

const ERPOptionCheckboxRowContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: "12px",
});

const ERPOptionCheckboxContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginLeft: "15px",
});
