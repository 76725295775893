// TODO: set user on history objects on api
import "./index.scss";
import React from "react";
import Link from "../../../../../ui/link";
import View from "../../../../../ui/view";
import Utils from "../../../../../../modules/utils";
import Permissions from "../../../../../../modules/schemas/permissions";
import EditIcon from "../../../../../../assets/icons/edit";
import DeleteIcon from "../../../../../../assets/icons/delete";
import ApproveIcon from "../../../../../../assets/icons/approve-icon";
import RejectIcon from "../../../../../../assets/icons/reject-icon";
import CloseIcon from "../../../../../../assets/icons/close-co";
import UnresolvedIcon from "../../../../../../assets/icons/unresolved-co";
import InlineIcon from "../../../../../ui/icon/inline-icon.js";
import ModalBox from "../../../../../ui/modal-box";
import EmailIcon from "../../../../../../assets/icons/email-icon";
import EmailResendIcon from "../../../../../../assets/icons/resend-icon";
import ItemDeleteModal from "../../../../common/view-action-items/prod-co-cpn-delete-modal";
import ResendEmailModal from "../resend-email-modal";
import { FormModal } from "common/components/modals";
import { TextArea } from "common/components/inputs";
import { notesStyle } from "build/components/serializationModal";
import ReactTooltip from "react-tooltip";

class Actions extends View {
  constructor(props) {
    super(props);

    this.state = {
      co: props.co,

      modal: {
        open: false,
        call: Utils.noop,
        comment: "",
        heading: "",
        placeholder: "",
        subHeading: "",
        actionBtnText: "",
        type: "approve",
      },
      modalResendEmail: false,
    };

    this.toggleREModal = this.toggleREModal.bind(this);
    this.extractorOfResenders = this.extractorOfResenders.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({ co: props.co });
  }

  submit() {
    let co = this.state.co;
    co.status = "REVIEW";
    co.resolution = "PENDING";
    co.history = { action: "SUBMITTED", user: this.props.user.data._id };
    this.props.onChange(co);
  }

  archive() {
    let co = this.state.co;
    this.props.onDelete(co._id);
  }

  openModal(event) {
    let name = event.target.name;
    let modal = this.state.modal;

    if (name === "approve-btn") {
      modal.call = this.approve;
      modal.heading = "You are Approving This Change Order";
      modal.placeholder = "Enter your Comment";
      modal.subHeading = "Add a Comment Before Submitting Your Decision.";
      modal.actionBtnText = "APPROVE";
      modal.type = "approve";
    } else if (name === "reject-btn") {
      modal.call = this.reject;
      modal.heading = "You are Rejecting This Change Order";
      modal.placeholder = "Enter Your Comment";
      modal.subHeading = "Add a Comment Before Submitting Your Decision.";
      modal.actionBtnText = "REJECT";
      modal.type = "reject";
    } else if (name === "close-btn") {
      modal.call = this.close;
      modal.heading = "You Are Closing This Change Order";
      modal.placeholder = "Enter Your Comment";
      modal.subHeading = "Add a Comment Before Submitting Your Decision.";
      modal.actionBtnText = "CLOSE";
      modal.type = "close";
    }
    Utils.mandatoryComment(modal, modal.comment, modal.type);
    modal.open = true;

    this.setState(modal);
  }

  closeDeleteModal() {
    this.state.deleteModal = false;
    this.setState(this.state);
  }

  openDeleteModal() {
    this.state.deleteModal = true;
    this.setState(this.state);
  }

  approve() {
    let co = this.state.co;
    let modal = this.state.modal;
    let comment = modal.comment;
    modal.comment = "";
    co.status = "CLOSED";
    co.resolution = "APPROVED";
    co.history = {
      action: "APPROVED",
      user: this.props.user.data._id,
      comment: comment,
    };
    this.onClose();
    this.props.onChange(co);
  }

  reject() {
    let co = this.state.co;
    let modal = this.state.modal;
    let comment = modal.comment;
    modal.comment = "";
    co.status = "OPEN";
    co.resolution = "REJECTED";
    co.history = {
      action: "REJECTED",
      user: this.props.user.data._id,
      comment: comment,
    };
    this.onClose();
    this.props.onChange(co);
  }

  close() {
    let co = this.state.co;
    let modal = this.state.modal;
    let comment = modal.comment;
    modal.comment = "";
    co.status = "CLOSED";
    if (co.resolution !== "APPROVED") {
      co.resolution = co.resolution === "UNRESOLVED" ? "NONE" : "REJECTED";
    }
    co.history = {
      action: "CLOSED",
      user: this.props.user.data._id,
      comment: comment,
    };
    this.onClose();
    this.props.onChange(co, true);
  }
  onCommentChange(value) {
    let modal = this.state.modal;
    Utils.mandatoryComment(modal, value, modal.type);
    modal.comment = value;
    this.setState({ modal });
  }

  onClose() {
    const modal = {
      open: false,
      comment: "",
      valid: false,
      message: "",
      class: "",
    };
    this.setState({ modal });
  }

  toggleREModal(status) {
    this.setState({ modalResendEmail: status });
  }

  // Clean the data to get only neccesary parameters
  extractorOfResenders(resenders, list) {
    let resd = [];

    if (resenders.length) {
      resenders.forEach((item) => {
        resd.push({
          userId: (item.user && item.user._id) || item._id || "",
          avatarId: (item.user && item.user.avatar) || item.avatar || "",
          src: "",
          email: (item.user && item.user.email) || item.email || item || "",
          username: item.user
            ? `${item.user.firstName} ${item.user.lastName}`
            : item.firstName
            ? `${item.firstName} ${item.lastName}`
            : "Anonymous",
          list,
        });
      });
    }

    return resd;
  }

  render() {
    const { co, modal } = this.state;
    let user = this.props.user.data;
    let { isActionPerformed, isInApproverList, isAdministrator } =
      this.props.actionAndActionStatus;
    let showRevisionsInProgressModal =
      co.resolution === "FINALIZING" && this.props.showRevisionProgressModal;
    let actionButtonClass = modal.type === "reject" ? "danger" : "filled";
    actionButtonClass = `${actionButtonClass} ${
      modal.message ? "action-button-disabled" : ""
    }`;

    let markup = (
      <div className="actions-block">
        {(() => {
          switch (co.status) {
            case "DRAFT": {
              let block = (
                <div>
                  {this.state.deleteModal && (
                    <ItemDeleteModal
                      onCloseDeleteModal={this.closeDeleteModal}
                      onDeleteBtnClick={this.archive}
                      headingTxt="Delete Change Order?"
                      bodyTxt="Deleting a Change Order will permanently remove it from your account. This action cannot be undone."
                    />
                  )}
                  {Permissions.can(
                    "approve",
                    "change_order",
                    this.props.user.data
                  ) &&
                    (isInApproverList || isAdministrator) && (
                      <Link to={`/changeorder/edit/${co._id}`}>
                        <button className="action-btn edit-btn">
                          {" "}
                          <InlineIcon>
                            <EditIcon />
                          </InlineIcon>{" "}
                          EDIT
                        </button>
                      </Link>
                    )}
                  {Permissions.can(
                    "approve",
                    "change_order",
                    this.props.user.data
                  ) &&
                    (isInApproverList || isAdministrator) && (
                      <button
                        onClick={this.openDeleteModal}
                        className="action-btn delete-btn"
                      >
                        <InlineIcon>
                          <DeleteIcon />
                        </InlineIcon>
                        DELETE
                      </button>
                    )}
                </div>
              );
              return block;
            }

            case "OPEN": {
              if (co.resolution === "UNRESOLVED") {
                let block = (
                  <div>
                    {Permissions.can(
                      "approve",
                      "change_order",
                      this.props.user.data
                    ) &&
                      isInApproverList && (
                        <button
                          className={`action-btn ${
                            isActionPerformed ? "disabled" : ""
                          }`}
                          name="approve-btn"
                          onClick={
                            !isActionPerformed && isInApproverList
                              ? this.openModal
                              : null
                          }
                        >
                          <InlineIcon>
                            <ApproveIcon />
                          </InlineIcon>
                          APPROVE
                        </button>
                      )}

                    {Permissions.can(
                      "approve",
                      "change_order",
                      this.props.user.data
                    ) &&
                      isInApproverList && (
                        <button
                          className={`action-btn ${
                            isActionPerformed ? "disabled" : ""
                          }`}
                          name="reject-btn"
                          onClick={
                            !isActionPerformed && isInApproverList
                              ? this.openModal
                              : null
                          }
                        >
                          <InlineIcon>
                            <RejectIcon />
                          </InlineIcon>
                          REJECT
                        </button>
                      )}

                    {Permissions.can(
                      "edit",
                      "change_order",
                      this.props.user.data
                    ) &&
                      (isInApproverList || isAdministrator) && (
                        <button
                          className={"action-btn"}
                          name="close-btn"
                          onClick={this.openModal}
                        >
                          <InlineIcon>
                            <CloseIcon />
                          </InlineIcon>
                          CLOSE
                        </button>
                      )}

                    {
                      <button className={`resolution-btn ${co.resolution}`}>
                        {" "}
                        <InlineIcon>
                          <UnresolvedIcon />
                        </InlineIcon>
                        {co.resolution}
                      </button>
                    }
                  </div>
                );
                return block;
              } else if (co.resolution === "FINALIZING") {
                return (
                  <button className={`resolution-btn ${co.resolution}`}>
                    <InlineIcon>
                      <RejectIcon />
                    </InlineIcon>
                    {co.resolution}
                  </button>
                );
              } else {
                let block = (
                  <div>
                    {Permissions.can(
                      "approve",
                      "change_order",
                      this.props.user.data
                    ) &&
                      (isInApproverList || isAdministrator) &&
                      co.resolution !== "APPROVED" && (
                        <Link to={`/changeorder/edit/${co._id}`}>
                          <button className={"action-btn edit-btn"}>
                            <InlineIcon>
                              <EditIcon />
                            </InlineIcon>{" "}
                            EDIT
                          </button>
                        </Link>
                      )}
                    {Permissions.can(
                      "edit",
                      "change_order",
                      this.props.user.data
                    ) &&
                      (isInApproverList || isAdministrator) && (
                        <button
                          className={"action-btn"}
                          name="close-btn"
                          onClick={this.openModal}
                        >
                          <InlineIcon>
                            <CloseIcon />
                          </InlineIcon>
                          CLOSE
                        </button>
                      )}
                    {
                      <button className={`resolution-btn ${co.resolution}`}>
                        {" "}
                        <InlineIcon>
                          <RejectIcon />
                        </InlineIcon>
                        {co.resolution}
                      </button>
                    }
                  </div>
                );
                return block;
              }
            }

            case "CLOSED": {
              let block = (
                <div>
                  {showRevisionsInProgressModal ? (
                    <div className={"revisions-in-progress-modal"}>
                      <ModalBox
                        onClose={this.props.onRevisionsInProgressModalClose}
                      >
                        <div className="email-sent-block">
                          <InlineIcon>
                            <EmailIcon />
                          </InlineIcon>
                          <h1>Change Order finalizing..</h1>
                          <div className="email-info">
                            <span className="modal-text">
                              Notifications will be emailed when complete.
                              <br />
                              To check change order status, refresh the page.
                            </span>
                          </div>
                          <div className="bottom-section">
                            <div className="button-sections">
                              <button
                                onClick={
                                  this.props.onRevisionsInProgressModalClose
                                }
                              >
                                OK
                              </button>
                            </div>
                          </div>
                        </div>
                      </ModalBox>
                    </div>
                  ) : null}
                  {co.resolution === "APPROVED" && (
                    <button
                      className="action-btn resend-email"
                      onClick={(e) => {
                        this.toggleREModal(true);
                      }}
                      data-tip={
                        this.state.modalResendEmail ? "" : "Resend Change Order"
                      }
                      data-place="left"
                    >
                      <EmailResendIcon />
                    </button>
                  )}
                  {
                    <button className={`resolution-btn ${co.resolution}`}>
                      <InlineIcon>
                        {co.resolution !== "FINALIZING" ? (
                          <RejectIcon />
                        ) : (
                          <UnresolvedIcon />
                        )}
                      </InlineIcon>
                      {co.resolution}
                    </button>
                  }
                  {this.state.modalResendEmail && (
                    <ResendEmailModal
                      closeModal={(e) => this.toggleREModal(false)}
                      coId={this.props.co._id}
                      avatarUser={[
                        {
                          userId: user._id || "",
                          avatarId: (user.avatar && user.avatar._id) || "",
                          src: (user.avatar && user.avatar.src) || "",
                          email: user.email || "",
                          username: `${user.firstName} ${user.lastName}`,
                          list: "me",
                        },
                      ]}
                      approvers={this.extractorOfResenders(
                        this.props.co.approverList,
                        "approvers"
                      )}
                      notifiers={this.extractorOfResenders(
                        [
                          ...this.props.co.coInternalNotifyUsers,
                          ...this.props.co.coExternalNotifyUsers,
                        ],
                        "notifiers"
                      )}
                    />
                  )}
                </div>
              );
              return block;
            }

            default: {
              // noop
            }
          }
        })()}

        {modal.open && (
          <div className={"co-action-type-modal"}>
            <FormModal
              disableSubmit={modal.message}
              modalProps={modalProps}
              onClose={this.onClose}
              onSubmit={() => modal.call()}
              open={modal.open}
              submitLabel={modal.actionBtnText
                .toLowerCase()
                .replace(/^./, modal.actionBtnText[0].toUpperCase())}
              title={modal.heading}
            >
              <TextArea
                characterLimit={1000}
                className={modal.class}
                label={modal.subHeading}
                maxRows={8}
                minRows={8}
                onChange={this.onCommentChange}
                placeholder={modal.placeholder}
                styleProps={notesStyle}
              />
            </FormModal>
          </div>
        )}
      </div>
    );

    return markup;
  }
}

export default Actions;

const modalProps = {
  width: "32rem",
};
