import "./index.scss";
import React, {Component} from "react";
import SingleRow          from "./single-row";
import PerfectScrollbar   from 'react-perfect-scrollbar';
import tableDragger       from 'table-dragger';
import Utils              from "../../../../../modules/utils";
import $                  from "jquery";
import ReactDragListView  from 'react-drag-listview';
import { FormModal } from "common/components/modals";
import { client } from "graphql/apolloClient";
import { getComponent } from "graphql/query/componentQueries";

class CustomizedSpecificationsModal extends Component
{
    constructor(props)
    {
        super(props);
        this.dragDropTable;
        this.columnAvatar = '.dragItemClass';
        this.tableWrap    = '.table-wrapper-scroll';
        this.MinLength    = 1;
        this.MaxLength    = 30;
        this.state =
        {
            checkAll: false,
            mappedHeaders: Utils.clone(this.props.mappedHeaders),
            duroLabels: Utils.clone(this.props.duroLabels),
            collection: Utils.clone(this.props.collection),
            disableSaveButton: false,
            middleClass: "",
            customSpecsLoaded: false,
            customSpecForCategories: new Set()
        }

        this.onClose              = this.onClose.bind(this);
        this.checkAll             = this.checkAll.bind(this);
        this.renderColumnSettings = this.renderColumnSettings.bind(this);
        this.updateMapping        = this.updateMapping.bind(this);
        this.saveCustomizeFields  = this.saveCustomizeFields.bind(this);
        this.toggleCheckboxes     = this.toggleCheckboxes.bind(this);
        this.onMouseLeave         = this.onMouseLeave.bind(this);
        this.onDragStart          = this.onDragStart.bind(this);
        this.toggleSaveButton     = this.toggleSaveButton.bind(this);
        this.getDuplicateHeaders    = this.getDuplicateHeaders.bind(this);
        this.validateCharsLimit   = this.validateCharsLimit.bind(this);


        this.validateHeader = this.validateHeader.bind(this);
        this.validateUniqueness = this.validateUniqueness.bind(this);
        this.validateAll = this.validateAll.bind(this);

        this.dragProps = {
            onDragEnd: this.onMouseLeave,
            handleSelector: ".drag-drop-holder",
            ignoreSelector: "tr.ant-table-expanded-row",
            nodeSelector: "tr",
            lineClassName: "dragItemClass"
        };
    }

    componentDidMount()
    {
        let headers = this.state.mappedHeaders;
        let middleClass = "";
        let {isCheckAll, addMiddleClass} = this.toggleCheckboxes(headers);
        if(addMiddleClass)
        {
            middleClass = "middle";
        }
        this.loadCustomSpecs().then(() => {
            this.setState({
                checkAll: isCheckAll, 
                middleClass: middleClass
            });
        });
    }

    loadCustomSpecs() {
        const customSpecForCategories = new Set();
        const promises = [];
        
        this.state.collection.forEach(item => { 
            if (item.alias.toLowerCase() === "cmp") { 
                promises.push(
                    getComponent(client, item._id).then(({ component: gqlComp }) => {
                        customSpecForCategories.add(gqlComp.category);
                    })
                )
            } 
        })
        
        return Promise.all(promises).then(() => {
            let headers = { ...this.state.mappedHeaders };
            let duroLabels = [...this.state.duroLabels];
            const previousCollection = window.localStorage.getItem("previousCollection");

            // remove all existing custom spec headers only if the collection has changed
            if (previousCollection !== JSON.stringify(this.state.collection)) { 
                localStorage.setItem("previousCollection", JSON.stringify(this.state.collection));

                const customSpecKeys = Object.keys(headers).filter(key => key.startsWith("Custom Spec - "));
                customSpecKeys.forEach(key => {
                    delete headers[key];
                    const index = duroLabels.indexOf(key);
                    if (index !== -1) {
                        duroLabels.splice(index, 1);
                    }
                });
            }

            if (window.__categories && customSpecForCategories.size > 0) { 
                const categories = window.__categories.filter(category => customSpecForCategories.has(category.displayName));

                // create a new header for each custom spec only if it doesn't already exist
                categories.forEach(category => { 
                    if (category?.customSpecs?.length > 0) { 
                        category?.customSpecs?.forEach(spec => { 
                            const specKey = `Custom Spec - ${spec.name}`;
                            if (!headers[specKey]) {
                                headers[specKey] = {
                                    isChecked: headers["Custom Specs"]?.isChecked || false,
                                    userLabel: spec.name,
                                    ...(specKey.startsWith("Custom Spec - ") ? { parent: "Custom Specs" } : {})
                                };

                                const customSpecsIndex = duroLabels.indexOf("Custom Specs");
                                if (customSpecsIndex !== -1) {
                                    duroLabels.splice(customSpecsIndex + 1, 0, specKey);
                                }
                            }
                        })
                    }
                })
            };

            this.setState({ 
                customSpecForCategories: customSpecForCategories,
                customSpecsLoaded: true,
                mappedHeaders: headers,
                duroLabels: duroLabels
            });
        });
    }

    onDragStart()
    {
        $('.drag-drop-holder').find('.ui-icon').addClass('grabbing');
    }

    onMouseLeave(fromIndex, toIndex)
    {
        $('.drag-drop-holder').find('.ui-icon').removeClass('grabbing')
        let state = this.state;
        state.duroLabels.splice(toIndex, 0, state.duroLabels.splice(fromIndex, 1)[0])
        this.setState(state);
    }

    onClose()
    {
        this.setState({mappedHeaders: this.props.mappedHeaders});
        this.props.onClose("displaySpecificationsModal", false);
    }

    checkAll(e)
    {
        let headers = this.state.mappedHeaders;
        Object.keys(headers).forEach(function(headerItem){
            headers[headerItem].isChecked = e.target.checked;
        });
        let disableSaveButton = !e.target.checked;
        this.validateAll(headers);
        this.setState({mappedHeaders: headers, checkAll: e.target.checked, middleClass: "", disableSaveButton: disableSaveButton});
    }

    renderColumnSettings(){
            let markup = []
            let _this = this;
            let headers = this.state.mappedHeaders;
            let duroLabels = this.state.duroLabels;

            // get Custom Specs checked state
            const customSpecsChecked = headers["Custom Specs"]?.isChecked || false;

            duroLabels.forEach((Item) => {
                const headerSettings = {...headers[Item]};
                if (Item.startsWith("Custom Spec - ")) {
                    headerSettings.parentChecked = customSpecsChecked;
                }

                markup.push(
                    <SingleRow
                        onMouseLeave={this.onMouseLeave}
                        onDragStart={this.onDragStart}
                        key={Item}
                        duroLabel={Item}
                        headerSettings={headerSettings}
                        checkAll={_this.state.checkAll}
                        updateMapping={_this.updateMapping}
                />
            );
        })
        return markup
    }

    updateMapping(duroLabel, userLabel, isChecked, type)
    {
        let headers = this.state.mappedHeaders;
        let state = this.state;
        let oldValue = headers[duroLabel].userLabel;
        headers[duroLabel].isChecked = isChecked;
        headers[duroLabel].userLabel = userLabel;

        // handles Custom Specs children
        if (duroLabel === "Custom Specs") {
            Object.keys(headers).forEach(key => {
                if (key.startsWith("Custom Spec - ")) {
                    headers[key].isChecked = isChecked;
                }
            });
        } else if (duroLabel.startsWith("Custom Spec - ")) { 
            let allChildrenUnchecked = true;
            Object.keys(headers).forEach(key => {
                if (key.startsWith("Custom Spec - ") && headers[key].isChecked) {
                    allChildrenUnchecked = false;
                }
            });

            // unchecks the parent if all children are unchecked
            if (allChildrenUnchecked) {
                headers["Custom Specs"].isChecked = false;
            }
        }

        let disableSaveButton = false;
        this.validateHeader(duroLabel, oldValue);
        let middleClass = "";
        let {isCheckAll, addMiddleClass} = this.toggleCheckboxes(headers);
        disableSaveButton = this.toggleSaveButton(headers, isCheckAll, addMiddleClass);
        if(addMiddleClass)
        {
            middleClass = "middle";
        }
        this.setState({mappedHeaders: headers, checkAll: isCheckAll, middleClass: middleClass, disableSaveButton: disableSaveButton});
    }

    validateHeader(duroLabel, userLabel){
        let headers = this.state.mappedHeaders;
        headers[duroLabel].invalid = false;
        let dupHeaders = [];
        this.validateCharsLimit(headers, duroLabel);
        if(!headers[duroLabel].invalid){
            dupHeaders = this.validateUniqueness(duroLabel, userLabel);
        }

    }

    validateUniqueness(durolabel, userLabel="")
    {
        let headers = this.state.mappedHeaders;
        let duplicateHeaders = []
        if(headers[durolabel].userLabel){
            let {count, dupHeaders, oldDuplicates} = this.getDuplicateHeaders(durolabel, userLabel);
            if(count > 1){
                dupHeaders.forEach(function(header){
                    headers[header].invalid = true;
                    headers[header].message = "Value must be unique";
                })
            }else{
                headers[durolabel].invalid = false;
                headers[durolabel].message = "";
            }
            if(oldDuplicates.length < 2){
                oldDuplicates.forEach(function(header){
                    headers[header].invalid = false;
                    headers[header].message = "";
                })
            }
            duplicateHeaders = dupHeaders;
        }

        return duplicateHeaders;
    }

    validateCharsLimit(headers, duroLabel){
        if(headers[duroLabel].isChecked){
            if(headers[duroLabel].userLabel.length < this.MinLength)
            {
                headers[duroLabel].invalid = true;
                headers[duroLabel].message = `Value must contain at least ${this.MinLength} character`;
            }
            else if(headers[duroLabel].userLabel.length > this.MaxLength)
            {
                headers[duroLabel].invalid = true;
                headers[duroLabel].message = `Value must be less than ${this.MaxLength} characters`;
            }
        }
        else
        {
            headers[duroLabel].invalid = false;
            headers[duroLabel].message = "";
        }
    }

    getDuplicateHeaders(duroLabel, prevValue)
    {
        let headers = this.state.mappedHeaders;
        let count = 0;
        let dupHeaders = [];
        let oldDuplicates = [];
        let isDisabled = !headers[duroLabel].isChecked;
        let userLabel = headers[duroLabel].userLabel;
        this.state.duroLabels.forEach((item) => {
            if(headers[item].userLabel === userLabel)
            {
                if(headers[item].isChecked)
                {
                    dupHeaders.push(item);
                    count++;
                    if(isDisabled && item != duroLabel){
                        oldDuplicates.push(item);
                    }
                 }
            }
            else if(prevValue && !isDisabled && headers[item].userLabel == prevValue && prevValue != userLabel){
                oldDuplicates.push(item);
            }
        })

        return {count: count, dupHeaders: dupHeaders, oldDuplicates: oldDuplicates};
    }

    validateAll(headers){
        let validationError = false;
        let validatedHeaders = [];
        let _this = this;
        this.state.duroLabels.forEach((headerItem) =>{
            if(headers[headerItem].isChecked)
            {
                // let userLabel = headers[headerItem].userLabel;
                this.validateCharsLimit(headers, headerItem);
                if(!headers[headerItem].invalid && !validatedHeaders.includes(headerItem))
                {
                    let dupHeaders = _this.validateUniqueness(headerItem);
                    validatedHeaders = [...validatedHeaders, ...dupHeaders];
                }
            }
            else
            {
                headers[headerItem].invalid = false;
                headers[headerItem].message = "";
            }
        });
    }



    toggleSaveButton(headers, isCheckAll, addMiddleClass)
    {
        let isInvalid = false;
        this.state.duroLabels.forEach((headerItem) => {
            if(headers[headerItem].invalid)
            {
                isInvalid = true;
            }
            else if(!isCheckAll && !addMiddleClass)
            {
                isInvalid = true;
            }
        });

        return isInvalid;
    }

    saveCustomizeFields()
    {
        this.props.updateCustomizedSettings(this.state.mappedHeaders,true,this.state.duroLabels);
        this.props.onClose("displaySpecificationsModal", false);
    }

    toggleCheckboxes(headers)
    {
        let isCheckAll = true;
        let addMiddleClass = false;
        Object.keys(headers).forEach(function(headerItem){
            if(!headers[headerItem].isChecked)
            {
                isCheckAll = false;
            }
            else
            {
                addMiddleClass = true;
            }
        });
        if(isCheckAll)
        {
            addMiddleClass = false;
            return {isCheckAll, addMiddleClass};
        }
        else
        {
            return {isCheckAll, addMiddleClass};
        }
    }

    handleOutsideClick(e)
    {
        if(!this.node.contains(e.target) || e.target.classList.contains("background")) this.onClose();
    }

    render()
    {
        let markup = <div ref={node => { this.node = node;}}>
                    <FormModal 
                        onCancel={this.onClose}
                        onClose={this.onClose} 
                        onSubmit={!this.state.disableSaveButton ? this.saveCustomizeFields: function(){}}
                        open={true}
                        title={"Customize Your Fields"}
                     >
                        <div style={{paddingRight: '5px'}}>
                            <table className="customise-specifications-table-header">
                                <thead>
                                    <tr style={{ borderBottom: '0'}}>
                                        <th style={{ width: '35px'}}>
                                        <div className="checkbox-holder">
                                                <input
                                                    type="checkbox"
                                                    name="check-all"
                                                    className="large"
                                                    checked={this.state.checkAll}
                                                    onChange={(e)=>this.checkAll(e)}
                                                />
                                                <label htmlFor="check-all"
                                                    className={this.state.middleClass}
                                                />
                                        </div>
                                        </th>
                                        <th>
                                            DURO
                                        </th>
                                        <th style={{ width: '40px'}}></th>
                                        <th style={{ textAlign: 'left'}}>
                                            YOUR LABELS
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                            <ReactDragListView {...this.dragProps}>
                                <PerfectScrollbar className="table-wrapper-scroll">
                                    <table className="customise-specifications-table-content" id="customize-specifications-data">
                                        <tbody>
                                            {this.renderColumnSettings()}
                                        </tbody>
                                    </table>
                                </PerfectScrollbar>
                            </ReactDragListView>
                        </div>
                    </FormModal>
                    </div>
        return markup;
    }
}

export default CustomizedSpecificationsModal;
