import { User } from "common/models";
import {
  ClientModulesSchemaValidationResult,
  ValidationErrorGroup,
} from "features/changeorders";

// matches the getChangeOrderRowFragment
export interface AssemblyChild {
  component: SuperComponent;
  id: string;
}

export type SuperComponent = {
  oid?: string;
  path: string[];
  id: string;
  treeId: string;
  cpn: {
    displayValue: string;
    id: string;
  };
  name: string;
  lastReleaseRevision?: SuperComponent;
  legacyNextRevision: string;
  previousRevisionValue: string;
  modified: boolean;
  status: string;
  previousStatus: string | null;
  lastModified: string;
  eid: string;
  alias: string;
  revisionValue: string;
  primarySource: {
    minQuantity?: number | null;
  };
  assemblyParents?: SuperComponent[];
  vendorInfo?: {
    currentVendors: string[];
  };
  revisionManaged?: boolean;
  error?: ClientModulesSchemaValidationResult;
  errors?: ClientModulesSchemaValidationResult[];
  errorType?: ClientModulesSchemaValidationResult["type"];
  errorGroup?: ValidationErrorGroup;
  requiresValidation?: boolean;
  __typename?: string;
};

export type BaseSuperComponent = Omit<SuperComponent, "treeId" | "path">;

export enum ChangeOrderAction {
  APPROVED = "APPROVED",
  CLOSED = "CLOSED",
  CREATED = "CREATED",
  EDITED = "EDITED",
  NONE = "NONE",
  REJECTED = "REJECTED",
  RESENT = "RESENT",
  SUBMITTED = "SUBMITTED",
  VIEWED = "VIEWED",
}

export function isChangeOrderERPItemType(
  value: string
): value is ChangeOrderERPItemType {
  return value in ChangeOrderERPItemType;
}

export enum ChangeOrderERPItemType {
  LOT_NUMBERED = "LOT_NUMBERED",
  NONE = "",
  SERIALIZED = "SERIALIZED",
  STANDARD = "STANDARD",
}

export interface ChangeOrderHistoryEvent {
  action: ChangeOrderAction;
  id: string | null;
  comment: string;
  created: string;
  user: User;
}

export interface ERPOptionsEffectivity {
  endDate: string;
  startDate: string;
  overrideExisting: boolean;
  isEnabled: boolean;
}
export interface ERPOptionsItemType {
  isEnabled: boolean;
  overrideExisting: boolean;
  value: ChangeOrderERPItemType;
}

export interface ERPOptionsAdditionalPayloadForNotifications {
  childComponent: boolean;
  parentAssemblies: boolean;
}

export interface ChangeOrderUser {
  id: string;
  email: string;
  groups: any[];
  firstName: string;
  lastName: string;
}

export interface ExternalEmailsNotified {
  id: string;
  email: string;
}

export interface ChangeOrderApprover {
  id: string;
  email: string;
  groups: any[];
  firstName: string;
  lastName: string;
}

export interface Approver {
  action: string;
  invitedAt: number | null;
  performedAt: null;
  user: ChangeOrderUser | ChangeOrderApprover;
}

export type DocumentSpecs = {
  type: string;
  revision?: string;
  status?: string;
  lastModified: number;
};

export type DocumentFile = {
  id: string;
  name: string;
  size: number;
  mime?: string;
};

export type DocumentLink = {
  document: DocumentFile;
  specs: DocumentSpecs;
};

export enum EcoDefaultApproval {
  FIRST_IN = "FIRST_IN",
  MAJORITY = "MAJORITY",
  UNANIMOUS = "UNANIMOUS",
}

export enum ChangeOrderStatus {
  CLOSED = "CLOSED",
  DRAFT = "DRAFT",
  OPEN = "OPEN",
}
export type ChangeOrderFormFields = {
  name: string;
  description: string;
  type: string;
  erpOptions: {
    effectivity: ERPOptionsEffectivity;
    itemType: ERPOptionsItemType;
    additionalPayloadForNotifications: ERPOptionsAdditionalPayloadForNotifications;
  } | null;
};

export const enum ChangeOrderType {
  DCO = "DCO",
  ECO = "ECO",
  MCO = "MCO",
}

// Serialized effectivity needed as PLM-API expects
// unix timestamps and core API expects ISO strings
export type PlmAPIEffectivity = Omit<
  ERPOptionsEffectivity,
  "startDate" | "endDate"
> & {
  startDate: number;
  endDate: number;
};

export type ChangeOrderPLMApi = Omit<
  ChangeOrder,
  "erpOptions" | "approvers"
> & {
  erpOptions: {
    effectivity: PlmAPIEffectivity;
    itemType: ERPOptionsItemType;
    additionalPayloadForNotifications: ERPOptionsAdditionalPayloadForNotifications;
  };
  approvers: ChangeOrderUser[];
};

export interface ChangeOrder extends ChangeOrderFormFields {
  tree: SuperComponent[];
  treeData: Record<string, SuperComponent>;
  company: {
    id: string;
  };
  approvalType: EcoDefaultApproval;
  id: string;
  creator: ChangeOrderUser;
  created: string;
  archived: boolean;
  alias: string;
  con: Con;
  components: SuperComponent[];
  products: SuperComponent[];
  revisionValue: string;
  history: ChangeOrderHistoryEvent[];
  exportBundleKey: string;
  approvers: Approver[];
  documentLinks: DocumentLink[];
  coInternalNotifyUsers: ChangeOrderUser[];
  coExternalNotifyUserEmails: string[];
  status: ChangeOrderStatus;
  resolution: string;
  library: {
    id: string;
  };
  type: ChangeOrderType;
  lastModified: string;
  isParentInclude: boolean;
  productRevisions: [];
  componentRevisions: [];
  unchangedComponents: [];
  unchangedProducts: [];
}

export interface Con {
  displayValue: string;
  id: string;
}
