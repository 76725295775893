import { FC } from "react";
import { Box, SelectChangeEvent, styled } from "@mui/material";
import { CustomDuroCheckbox } from "common/components/checkbox";
import { Select, Switch } from "common/components/inputs";
import { InputFieldVariants } from "common/constants";
import { colorPalette } from "@duro/themes";
import mixpanel from "common/mixpanel";
import {
  ChangeOrderERPItemType,
  ERPOptionsItemType,
} from "design/models/changeorder";
import { defaultERPItemType, sdk } from "features/changeorders/sdk";
import Utils from "v1/modules/utils";

const checkboxLabelStyles = { fontSize: "0.75rem", marginLeft: "0.40rem" };
const itemTypeOptions = Object.keys(ChangeOrderERPItemType).filter(opt=>opt!=="NONE")
const selectComponentStyles = { marginBottom: "1rem" };
const selectInputStyleProps = {
  sx: {
    fontSize: "1rem",
    padding: "0.625rem",
  },
};
const switchLabelStyles = {
  color: colorPalette.silver,
  fontSize: "0.8rem",
  width: "6rem",
};

export const ERPItemType = () => {
  const coId = sdk.storage.coId.useStore();
  const [itemType, setERPItemType] =
    sdk.storage.form.erpOptions.itemType.useStore();

  const { isEnabled, value, overrideExisting } = itemType ?? defaultERPItemType;
  // const itemTypeList = Utils.getItemTypeList();
  // const itemTypeOptions =
  //   itemTypeList
  //     ?.filter((opt: { displayName: string }) => opt.displayName !== "Select")
  //     ?.map((opt: { value: any }) => opt.value) ?? [];
  return (
    <CustomItemTypeComponent>
      <CustomSwitch>
        <Switch
          defaultValue={isEnabled}
          label={"Bulk Set Item Type: "}
          labelPosition={"start"}
          labelStyle={switchLabelStyles}
          onChange={() => {
            mixpanel.track("Change Order", {
              "Change Order UI Version": "v2",
              "Change Order": coId,
              operation: "Set Item Type Enabled/Disabled",
              "Item Type Enabled": !isEnabled,
            });

            setERPItemType((prev) => ({
              ...prev,
              isEnabled: prev?.isEnabled ? !prev.isEnabled : !isEnabled,
            }));
          }}
        />
      </CustomSwitch>
      <StyledComp isEnabled={isEnabled}>
        <Select
          componentStyles={selectComponentStyles}
          defaultValue={value}
          disabled={!isEnabled}
          displayEmpty={true}
          inputProps={selectInputStyleProps}
          isRequired={false}
          label={"Select Item Type"}
          onChange={(event: SelectChangeEvent) => {
            mixpanel.track("Change Order", {
              "Change Order UI Version": "v2",
              "Change Order": coId,
              operation: "Select Item Type",
              "Item Type": event.target.value,
            });
            setERPItemType((prev) => ({
              ...prev,
              value: event.target.value as ChangeOrderERPItemType,
            }));
          }}
          options={itemTypeOptions}
          placeholder={"Select"}
          variant={InputFieldVariants.STANDARD}
        />
      </StyledComp>
      {/* <CustomCheckbox>
        <CustomDuroCheckbox
          checkBoxLabel={"Override"}
          checked={overrideExisting}
          checkboxFontSize={"1rem"}
          disabled={!isEnabled}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            mixpanel.track("Change Order", {
              "Change Order UI Version": "v2",
              "Change Order": coId,
              operation: "Override Item Type",
              "Item Type Override": event.target.checked,
            });
            setERPItemType((prev) => ({
              ...prev,
              overrideExisting: event.target.checked,
            }));
          }}
          labelStyles={checkboxLabelStyles}
        />
      </CustomCheckbox> */}
    </CustomItemTypeComponent>
  );
};

const StyledComp = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isEnabled",
})<{ isEnabled: boolean }>(({ isEnabled }) => ({
  color: isEnabled ? colorPalette.white : colorPalette.disabled,
  paddingRight: "0.25rem",
  width: "60%",
}));

const CustomCheckbox = styled(Box)({
  marginTop: "0.6rem",
});

const CustomSwitch = styled(Box)({
  marginTop: "1rem",
});

const CustomItemTypeComponent = styled(Box)({
  alignItems: "center",
  display: "flex",
});
